import styled from "styled-components";

export const Footer = styled.footer`
  height: 70px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  display: flex;
  align-items: center;
  border-top: 1.5px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: layoutFooter;

  .footer-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 25px;
    b,
    strong,
    a {
      font-weight: 900;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
