export function getModuleRequest({ id_matricula }) {
  return {
    type: "@module/GET_MODULE_REQUEST",
    payload: { id_matricula },
  };
}

export function getModuleSuccess(data) {
  return {
    type: "@module/GET_MODULE_SUCCESS",
    payload: { data },
  };
}

export function getModuleFailure(data) {
  return {
    type: "@module/GET_MODULE_FAILURE",
    payload: { data },
  };
}
