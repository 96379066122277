const enUS = {
  title: "Search results",
  description: "Check a summary of your activities.",
  results: "results",
  result: "result",
  searchReturned: "Your search returned",
  searchFilterCategory: "Category",
  searchFilterOrderBy: "Order by",
};

const ptBR = {
  title: "Resultado da pesquisa",
  description: "Confira um resumo de suas atividades.",
  results: "resultados",
  result: "resultado",
  searchReturned: "Sua pesquisa gerou",
  searchFilterCategory: "Categoria",
  searchFilterOrderBy: "Ordenar por",
};

export default { enUS, ptBR };
