import * as Yup from "yup";

export default async function signin(data, formRef, _e) {
  try {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      email: Yup.string()
        .email()
        .required(_e({ id: "validators.requiredField" })),
      password: Yup.string().required(_e({ id: "validators.requiredField" })),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    return true;
  } catch (err) {
    const validationErrors = {};
    if (err instanceof Yup.ValidationError) {
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      formRef.current.setErrors(validationErrors);
    }
    return false;
  }
}
