const enUS = {
  pageTitle: "Classroom",
  modules: "Modules",
  modulesDisciplines: "Modules and disciplines.",
  aboutTeachers: "About teachers",
  checkSummary: "Check a summary of your activities.",
  commonQuestions: "Common questions",
  frequentlyAsked: "Frequently asked questions about the modules.",
  logIn: "Log in",
  back: "Back",
  modulesBanner: "modules",
  duration: "Duration",
  continue: "Continue",
  aboutCourse: "About the course",
};

const ptBR = {
  pageTitle: "Sala de Aula",
  modules: "Módulos",
  modulesDisciplines: "Módulos e disciplinas.",
  aboutTeachers: "Sobre os professores",
  checkSummary: "Confira um resumo de suas atividades.",
  commonQuestions: "Perguntas Frequentes",
  frequentlyAsked: "Dúvidas mais frequentes sobre os módulos.",
  logIn: "Conecte-se",
  back: "Voltar",
  modulesBanner: "módulos",
  duration: "Duração",
  continue: "Continuar",
  aboutCourse: "Sobre o curso",
};

export default { enUS, ptBR };
