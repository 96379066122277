import styled from "styled-components";

/**
 * @todo box-shadow com cor hardcode
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 100%;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    .title {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
    a {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      text-decoration-line: underline;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding: 15px 25px;
      &:not(:last-child) {
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      }
      .text-container {
        max-width: 80%;
      }
      .category {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.84375px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        margin-bottom: 4px;
      }
      .title {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        display: block;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .date {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        min-width: 60px;
      }
    }
  }
`;
