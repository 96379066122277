import React from "react";
import { useIntl } from "react-intl";

import { IconWorkProgress } from "~/images";
import documentTitle from "~/utils/documentTitle";

import { Container } from "./styles";

function WorkInProgress() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "notes.title" }));

  return (
    <Container>
      <img src={IconWorkProgress} alt="Work in progress" />
    </Container>
  );
}

export default WorkInProgress;
