import styled from "styled-components";

/**
 * @todo box-shadow com cor hardcode
 */
export const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  .preview {
    max-width: 245px;
    flex: 1;
    background-color: ${({ theme }) =>
      theme.palette.quaternary[theme.palette.type]};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      width: 40px;
      height: 40px;
      background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }
  .course-preview-content {
    flex: 1;
    padding: 20px 30px;
    .top {
      display: flex;
      justify-content: space-between;
      flex: 1;
      align-items: center;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .category {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.84375px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      }
      .title {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        text-transform: capitalize;
      }
      .progress-area {
        margin-top: 20px;
        p {
          margin: 0;
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.4375px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          &.porcent {
            font-weight: 800;
            font-size: 11px;
            line-height: 13px;
            color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
          }
        }
        .progress-mts {
          display: flex;
          align-items: center;
          .bar-bg {
            display: block;
            background: ${({ theme }) =>
              theme.palette.quaternary[theme.palette.type]};
            border-radius: 5px;
            width: 170px;
            height: 5px;
            margin-right: 15px;
            position: relative;
            .bar {
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              background: ${({ theme }) =>
                theme.palette.primary[theme.palette.type]};
              border-radius: 5px;
              height: 5px;
            }
          }
        }
      }
    }
    .bottom {
      display: grid;
      grid-auto-columns: 1fr;
      grid-column-gap: 1rem;
      grid-auto-flow: column;
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      .info-single {
        .label {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.375px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        }
        .text {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;
          color: ${({ theme }) => theme.palette.text[theme.palette.type]};
          text-transform: capitalize;
        }
        a {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.857143px;
          text-decoration-line: underline;
          color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
        }
      }
    }
    .continue {
      min-width: 163px;
      height: 50px;
      background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      text-decoration: none;
      transition: all 0.4s ease;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
