const enUS = {
  allRightsReserved: "All rights reserved.",
  developedBy: "Developed by",
};

const ptBR = {
  allRightsReserved: "Todos os direitos reservados.",
  developedBy: "Desenvolvido por",
};

export default { enUS, ptBR };
