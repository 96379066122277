const INITIAL_STATE = {};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@news/GET_NEWS_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
