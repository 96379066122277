import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { fakeApi } from "~/services/api";

import { getPagesSuccess } from "./actions";

export function* getPages() {
  try {
    const response = yield call(fakeApi.get, "pages");

    yield put(getPagesSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([takeLatest("@pages/GET_PAGES_REQUEST", getPages)]);
