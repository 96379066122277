import * as Yup from "yup";

export default async function validationInputResetForm(data, formRef, _e) {
  try {
    // Remove all previous errors
    formRef.current.setErrors({});
    const schema = Yup.object().shape({
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{10,}$/,
          _e({ id: "validators.passwordTooSimple" })
        )
        .required(_e({ id: "validators.requiredField" })),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        _e({ id: "validators.passwordsDoNotMatch" })
      ),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    return true;
  } catch (err) {
    const validationErrors = {};
    if (err instanceof Yup.ValidationError) {
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      formRef.current.setErrors(validationErrors);
    }
    return false;
  }
}
