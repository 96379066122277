import React from "react";
import { MdCheck } from "react-icons/md";

import { Checkbox, FormControlLabel, useTheme } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Container, useStyles } from "./styles";

export default function Check({ label, name, checked, onClick, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            className={classes.root}
            disableRipple
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)}>
                <MdCheck
                  color={theme.palette.tertiary[theme.palette.type]}
                  size={15}
                />
              </span>
            }
            checked={checked}
            onChange={onClick}
            name={name}
            icon={<span className={classes.icon} />}
            {...rest}
          />
        }
        label={label}
      />
    </Container>
  );
}

Check.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

/**
 * @todo label hardcode deve ser aplicado internacionalização
 */
Check.defaultProps = {
  label: "Check",
  name: "",
  checked: false,
};
