import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import { Loading, Title, Pagination } from "~/components";
import { ROUTES } from "~/constants/routes";
import { getNotifysRequest } from "~/store/modules/notifys/actions";
import { formatDate } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, BtnCallback } from "./styles";

export default function Notifys() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "notifications.title" }));

  const dispatch = useDispatch();
  const { loading, notifications, total } = useSelector(
    (state) => state.notifys
  );
  const [page, setPage] = useState(1);
  const [notificationsToDisplay, setNotificationsToDisplay] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    dispatch(
      getNotifysRequest({
        from: 1,
        to: 50,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (page % 10 === 0) {
      dispatch(
        getNotifysRequest({
          from: ((page * 2) / 10 - 1) * 50 + 1,
          to: ((page * 2) / 10) * 50,
        })
      );
    }
  }, [dispatch, page, notificationsToDisplay]);

  useEffect(() => {
    setNotificationsToDisplay(
      notifications.slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
    );
  }, [page, notifications]);

  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <BtnCallback to={ROUTES.DASHBOARD}>
            <span>
              <MdKeyboardArrowLeft
                size={16}
                color={theme.palette.primary[theme.palette.type]}
              />
            </span>
            Voltar
          </BtnCallback>
          <ContainerMD maxWidth="md">
            <div className="content-wrapper">
              <div className="title-wrapper">
                <Title>{_e({ id: "notifications.title" })}</Title>
                <p>{_e({ id: "notifications.description" })}</p>
              </div>
              <div className="notifications-container">
                <ul className="ul-notifys">
                  {notificationsToDisplay.map(({ mat_notificacao }) => (
                    <li key={String(mat_notificacao.id_notificacao)}>
                      <div className="content">
                        <div className="category">
                          {mat_notificacao.ds_titulo}
                        </div>
                        <div className="title-noti">
                          {mat_notificacao.ds_notificacao}
                        </div>
                        <div className="time">
                          {formatDate(parseISO(mat_notificacao.dt_cadastro))}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Pagination
                  postsPerPage={5}
                  totalPosts={total}
                  setPaged={(number) => setPage(number)}
                  currentPage={page}
                />
              </div>
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}
