export function getCourseRequest(payload) {
  return {
    type: "@course/GET_COURSE_REQUEST",
    payload,
  };
}

export function getCourseSuccess(data) {
  return {
    type: "@course/GET_COURSE_SUCCESS",
    payload: { data },
  };
}

export function getCourseFailure(data) {
  return {
    type: "@course/GET_COURSE_FAILURE",
    payload: { data },
  };
}

export function getLibraryRequest() {
  return {
    type: "@course/GET_LIBRARY_REQUEST",
  };
}

export function getLibrarySuccess(data) {
  return {
    type: "@course/GET_LIBRARY_SUCCESS",
    payload: { data },
  };
}

export function getLibraryFailure(data) {
  return {
    type: "@course/GET_LIBRARY_FAILURE",
    payload: { data },
  };
}
