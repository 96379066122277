/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import {
  BannerCourse,
  CoursePreview,
  Modules,
  Questions,
  Loading,
} from "~/components";
import { Course, Preview } from "~/images";
import { getCourseRequest } from "~/store/modules/course/actions";
import { getModuleRequest } from "~/store/modules/module/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CoursePage() {
  let query = useQuery();
  let { course_id } = useParams();

  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const { modules } = useSelector((state) => state.modules);

  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "course.pageTitle" }));

  useMemo(() => {
    dispatch(getCourseRequest({ course_id }));
    dispatch(getModuleRequest({ id_matricula: query.get("id_mat") }));
  }, [dispatch]);

  const [courseState, setCourseState] = useState([]);

  useEffect(() => {
    setCourseState(course);
  }, [course]);

  const { banner, coursePreview, questions } = courseState;

  return (
    <Container>
      {isEmpty(courseState) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <div className="banner-container">
            <BannerCourse
              // ratting={banner.ratting}
              // stars={banner.stars}
              title={banner.title}
              modules={banner.modules}
              duration={banner.duration}
              description={banner.description}
              url={banner.url}
              image={banner.image || Course}
            />
          </div>
          <ContainerMD maxWidth="lg">
            <div className="course-preview-wrapper">
              <CoursePreview
                image={Preview}
                category={coursePreview.category}
                title={coursePreview.title}
                progress={coursePreview.progress}
                url={coursePreview.url}
                urlClass={coursePreview.urlClass}
                phasesTotal={coursePreview.phasesTotal}
                phasesComplete={coursePreview.phasesComplete}
                startDate={parseISO(coursePreview.startDate)}
                endDate={parseISO(coursePreview.endDate)}
                situation={coursePreview.situation}
                docsTotal={coursePreview.docsTotal}
                docsComplete={coursePreview.docsComplete}
                urlDocs={coursePreview.urlDocs}
                disciplineProgress={coursePreview.disciplineProgress}
                disciplineTotal={coursePreview.disciplineTotal}
                disciplineComplete={coursePreview.disciplineComplete}
              />
            </div>
            <Modules
              title={_e({ id: "course.modules" })}
              description={_e({ id: "course.modulesDisciplines" })}
              modules={modules}
            />
            {/* <Tutors
              title={_e({ id: "course.aboutTeachers" })}
              description={_e({ id: "course.checkSummary" })}
              tutors={tutors}
            /> */}

            <Questions
              title={_e({ id: "course.commonQuestions" })}
              description={_e({ id: "course.frequentlyAsked" })}
              questions={questions}
            />
            <div className="course-preview-wrapper">
              <CoursePreview
                image={Preview}
                category={coursePreview.category}
                title={coursePreview.title}
                progress={coursePreview.progress}
                url={coursePreview.url}
                urlClass={coursePreview.urlClass}
                phasesTotal={coursePreview.phasesTotal}
                phasesComplete={coursePreview.phasesComplete}
                startDate={parseISO(coursePreview.startDate)}
                endDate={parseISO(coursePreview.endDate)}
                situation={coursePreview.situation}
                docsTotal={coursePreview.docsTotal}
                docsComplete={coursePreview.docsComplete}
                urlDocs={coursePreview.urlDocs}
                disciplineProgress={coursePreview.disciplineProgress}
                disciplineTotal={coursePreview.disciplineTotal}
                disciplineComplete={coursePreview.disciplineComplete}
              />
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}

export default CoursePage;
