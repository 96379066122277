/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import {
  getCommentsSuccess,
  getCommentsFailure,
  postCommentSuccess,
  postCommentFailure,
  deleteFileSuccess,
  deleteFileFailure,
  postCommentReplySuccess,
  postCommentReplyFailure,
} from "./actions";

export function* getComments({
  payload,
  id_curso: id_curso_local,
  id_aula: id_aula_local,
}) {
  let id_curso;
  let id_aula;

  if (payload === undefined) {
    id_curso = id_curso_local;
    id_aula = id_aula_local;
  } else {
    id_curso = payload.id_curso;
    id_aula = payload.id_aula;
  }

  try {
    const response = yield call(api.get, API.COMMENTS_LIST, {
      id_curso,
      id_aula,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getCommentsSuccess(response.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(getCommentsFailure());
  }
}

export function* postComment({ payload }) {
  const { id_login } = yield select(userInfo);
  const { id_curso, id_aula, ds_comentario, data } = payload;

  let sendObject = {
    id_curso: Number(id_curso),
    id_aula,
    id_login,
    ds_comentario,
  };

  if (data && data.length > 0)
    sendObject.data = data.map((name) => {
      return { ds_arquivo: name };
    });

  try {
    const response = yield call(api.post, API.COMMENTS_CREATE, sendObject);

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getComments({ id_aula, id_curso });
    yield put(postCommentSuccess());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    return yield put(postCommentFailure());
  }
}

export function* deleteFile({ payload }) {
  const { id_arquivo } = payload;

  try {
    const response = yield call(
      api.delete,
      API.COMMENTS_FILE_DELETE,
      {},
      { data: { id_arquivo } }
    );

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(deleteFileSuccess());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    return yield put(deleteFileFailure());
  }
}

export function* postCommentReply({ payload }) {
  const { id_login } = yield select(userInfo);
  const { id_comentario, ds_resposta, id_aula, id_curso } = payload;

  try {
    const response = yield call(api.post, API.COMMENTS_REPLY_CREATE, {
      id_login,
      id_comentario,
      ds_resposta,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getComments({ id_aula, id_curso });
    yield put(postCommentReplySuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(postCommentReplyFailure());
  }
}

export default all([
  takeLatest("@comments/GET_COMMENTS_REQUEST", getComments),
  takeLatest("@comments/POST_COMMENT_REQUEST", postComment),
  takeLatest("@comments/POST_COMMENT_REPLY_REQUEST", postCommentReply),
  takeLatest("@comments/DELETE_FILE_REQUEST", deleteFile),
]);
