import styled from "styled-components";

/**
 * @todo remover cores em hardcode
 */
export const Container = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  list-style-type: none;
  li {
    &.page-item {
      margin-right: 15px;
      button {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #252525;
        background-color: transparent;
        border: 0px solid;
        outline: none;
        padding: 10px;
        transition: all 0.4s ease-in-out;
        &:hover,
        &:active,
        &:focus {
          box-shadow: inset 0 -5px 0 0 ${({ theme }) => theme.palette.primary[theme.palette.type]};
        }
      }
      &.active {
        button {
          box-shadow: inset 0 -5px 0 0 ${({ theme }) => theme.palette.primary[theme.palette.type]};
        }
      }
    }
    &.btn-paged {
      button {
        width: 47px;
        height: 47px;
        background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        border-radius: 67px;
        border: 0px solid;
        transition: all 0.4s ease-in-out;
        svg {
          fill: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
        }
      }
      &.disabled {
        button {
          background: ${({ theme }) =>
            theme.palette.tertiary[theme.palette.type]};
          svg {
            fill: ${({ theme }) => theme.palette.primary[theme.palette.type]};
          }
        }
      }
      &.left {
        margin-right: 65px;
      }
      &.right {
        margin-left: 50px;
      }
    }
  }
`;
