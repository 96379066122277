export function getFavoritesRequest({ id_grade, id_matricula }) {
  return {
    type: "@favorites/GET_FAVORITES_REQUEST",
    payload: { id_grade, id_matricula },
  };
}

export function getFavoritesSuccess(data) {
  return {
    type: "@favorites/GET_FAVORITES_SUCCESS",
    payload: { data },
  };
}

export function getFavoritesFailure() {
  return {
    type: "@favorites/GET_FAVORITES_FAILURE",
  };
}

export function postFavoritesRequest({
  id_aula,
  id_grade,
  id_matricula,
  tp_situacao,
}) {
  return {
    type: "@favorites/POST_FAVORITES_REQUEST",
    payload: { id_aula, id_grade, id_matricula, tp_situacao },
  };
}

export function postFavoritesSuccess() {
  return {
    type: "@favorites/POST_FAVORITES_SUCCESS",
  };
}

export function postFavoritesFailure() {
  return {
    type: "@favorites/POST_FAVORITES_FAILURE",
  };
}

export function editFavoritesRequest({
  id_aula,
  id_grade,
  id_matricula,
  tp_situacao,
}) {
  return {
    type: "@favorites/EDIT_FAVORITES_REQUEST",
    payload: { id_aula, id_grade, id_matricula, tp_situacao },
  };
}

export function editFavoritesSuccess() {
  return {
    type: "@favorites/EDIT_FAVORITES_SUCCESS",
  };
}

export function editFavoritesFailure() {
  return {
    type: "@favorites/EDIT_FAVORITES_FAILURE",
  };
}
