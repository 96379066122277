const enUS = {
  myCourses: "My courses",
  courseStart: "Begins",
  courseEnd: "Ends",
};

const ptBR = {
  myCourses: "Meus cursos",
  courseStart: "Início",
  courseEnd: "Fim",
};

export default { enUS, ptBR };
