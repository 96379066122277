import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { useTheme } from "@material-ui/core";

import { Container } from "./styles";

export default function Loading(props) {
  const theme = useTheme();
  return (
    <Container {...props}>
      <AiOutlineLoading3Quarters
        size={60}
        color={theme.palette.primary[theme.palette.type]}
      />
    </Container>
  );
}
