import Announcements from "./modules/announcements";
import BoxInfo from "./modules/boxInfo";
import Classroom from "./modules/classroom";
import Course from "./modules/course";
import CoursePreview from "./modules/coursePreview";
import Dashboard from "./modules/dashboard";
import Footer from "./modules/footer";
import Global from "./modules/global";
import Header from "./modules/header";
import library from "./modules/library";
import Login from "./modules/login";
import myCourses from "./modules/myCourses";
import notes from "./modules/notes";
import Notifications from "./modules/notifications";
import Search from "./modules/search";
import Validators from "./modules/validators";

export default {
  "en-US": {
    dashboard: Dashboard.enUS,
    coursePreview: CoursePreview.enUS,
    boxInfo: BoxInfo.enUS,
    global: Global.enUS,
    footer: Footer.enUS,
    header: Header.enUS,
    course: Course.enUS,
    login: Login.enUS,
    validators: Validators.enUS,
    classroom: Classroom.enUS,
    announcements: Announcements.enUS,
    notifications: Notifications.enUS,
    search: Search.enUs,
    myCourses: myCourses.enUs,
    notes: notes.enUS,
    library: library.enUS,
  },
  "pt-BR": {
    dashboard: Dashboard.ptBR,
    coursePreview: CoursePreview.ptBR,
    boxInfo: BoxInfo.ptBR,
    global: Global.ptBR,
    footer: Footer.ptBR,
    header: Header.ptBR,
    course: Course.ptBR,
    login: Login.ptBR,
    validators: Validators.ptBR,
    classroom: Classroom.ptBR,
    announcements: Announcements.ptBR,
    notifications: Notifications.ptBR,
    search: Search.ptBR,
    myCourses: myCourses.ptBR,
    notes: notes.ptBR,
    library: library.ptBR,
  },
};
