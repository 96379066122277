import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 25px;
  .accordion-title {
    display: flex;
    align-items: center;
    .id {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: normal;
      color: ${({ theme }) => theme.palette.quinary[theme.palette.type]};
      margin-right: 15px;
      display: block;
    }
    h4 {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: normal;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      margin-right: 40px;
      margin-bottom: 0;
    }
  }
`;

export const AccordionBase = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    marginBottom: -1,
    border: "0px solid",
    borderBottom: `1px solid
      ${theme.palette.quaternary[theme.palette.type]}`,
    padding: "0 20px",
    height: 70,
    "&$expanded": {
      height: 70,
    },
  },
  content: {
    margin: 0,
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    background: theme.palette.senary[theme.palette.type],
    padding: 30,
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.textTwo[theme.palette.type],
  },
}))(MuiAccordionDetails);
