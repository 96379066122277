import React, { useMemo } from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  createMuiTheme,
  ThemeProvider as MaterialProvider,
} from "@material-ui/core/styles";
import { ThemeProvider as StyledProvider } from "styled-components";

import { AppProvider } from "~/config/rootProvider";
import { theme as customTheme } from "~/styles/themes";
import generateSession from "~/utils/generateSession";

import { AUTO_CLOSE } from "./constants/toast";
import Routes from "./routes";
import history from "./services/history";
import GlobalStyle from "./styles/global";

function App() {
  const theme = useMemo(() => createMuiTheme(customTheme), []);

  useMemo(() => generateSession(), []);

  return (
    <AppProvider>
      <Router history={history}>
        <MaterialProvider theme={theme}>
          <StyledProvider theme={theme}>
            <Routes />
          </StyledProvider>
          <GlobalStyle />
        </MaterialProvider>
        <ToastContainer autoClose={AUTO_CLOSE} />
      </Router>
    </AppProvider>
  );
}

export default App;
