import React from "react";
import { useIntl } from "react-intl";

import { Form } from "@unform/web";

import { Button } from "~/components";
import { ROUTES } from "~/constants/routes";
import { PasswordConfirm } from "~/images";
import history from "~/services/history";
import documentTitle from "~/utils/documentTitle";

import { Container } from "./styles";

export default function SignIn() {
  documentTitle("Reset password");

  async function handleSubmit() {
    history.push(ROUTES.DEFAULT);
  }

  const { formatMessage: _e } = useIntl();

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <img src={PasswordConfirm} alt="Senha alterada" />
        <div className="signin">
          {_e({ id: "login.passwordUpdatedSuccessfully" })}
        </div>
        <Button type="submit">{_e({ id: "login.backToSite" })}</Button>
      </Form>
    </Container>
  );
}
