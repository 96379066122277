/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { IconFavorites, IconRightArrow, IconSearch, IconExit } from "~/images";

import Loading from "../Loading";
import Pagination from "../Pagination";
import {
  Container,
  FavoriteContainer,
  FavoriteItem,
  Header,
  InputContainer,
} from "./styles";

export default function FavoritesDrawer({ show, closeList }) {
  const [favoritesList, setFavoritesList] = useState(null);
  const [displayFavorites, setDisplayFavorites] = useState(null);
  const [favoritesLength, setFavoritesLength] = useState(0);
  const [newFavoriteInput, setNewFavoriteInput] = useState("");

  const { favorites, loading: favoritesLoading } = useSelector(
    (state) => state.favorites
  );

  const [page, setPage] = useState(1);

  useMemo(() => {
    if (favoritesList !== null) {
      setDisplayFavorites(
        favoritesList
          .filter((fav) => fav.mat_favorito_aula.tp_situacao === "A")
          .slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
      );
      setFavoritesLength(
        favoritesList.filter((fav) => fav.mat_favorito_aula.tp_situacao === "A")
          .length
      );
    }
  }, [favoritesList, page]);

  useEffect(() => {
    if (newFavoriteInput !== "") {
      setDisplayFavorites(
        favoritesList.filter((favorite) =>
          favorite.disciplina[0].capitulo[0].aula[0].crs_video.ds_tema
            .toLowerCase()
            .includes(newFavoriteInput.toLowerCase())
        )
      );
    } else {
      setDisplayFavorites(favoritesList);
    }
  }, [newFavoriteInput]);

  async function handleText(data) {
    setNewFavoriteInput(data.currentTarget.value);
  }

  useEffect(() => {
    if (favorites && favorites.length > 0) setFavoritesList(favorites);
  }, [favorites]);

  return (
    <Container show={show}>
      <Header>
        <button onClick={() => closeList(false)} type="button">
          <img src={IconExit} alt="Ícone fechar" />
        </button>
        <div>
          <img src={IconFavorites} alt="Ícone favoritos" />
          <h2>Favoritos</h2>
        </div>
        <p>Consulte suas aulas favoritas aqui.</p>
        <InputContainer>
          <img src={IconSearch} alt="Ícone Busca" />
          <input
            onChange={handleText}
            placeholder="Pesquise suas aulas ou disciplinas"
          />
        </InputContainer>
      </Header>
      <FavoriteContainer>
        {favoritesLoading ? (
          <Loading />
        ) : (
          displayFavorites &&
          displayFavorites.map((favorite) => (
            <FavoriteItem>
              <p className="date">23 do 11 2020</p>
              <div>
                <div>
                  <p>{favorite.crs_modulo.ds_modulo}</p>
                  <p>{favorite.disciplina[0].crs_disciplina.ds_disciplina}</p>
                  <p>
                    {
                      favorite.disciplina[0].capitulo[0].aula[0].crs_video
                        .ds_tema
                    }
                  </p>
                </div>
                <div>
                  <button type="button">
                    <img src={IconRightArrow} alt="Flecha para a direita" />
                  </button>
                </div>
              </div>
            </FavoriteItem>
          ))
        )}
      </FavoriteContainer>
      <Pagination
        postsPerPage={5}
        totalPosts={favoritesLength}
        setPaged={(number) => setPage(number)}
        currentPage={page}
      />
    </Container>
  );
}

FavoritesDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  closeList: PropTypes.func.isRequired,
};
