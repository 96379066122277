/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getNotesSuccess, getNotesFailure } from "./actions";

export function* getNotes() {
  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.get, API.NOTES_LIST, {
      id_login,
      // id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getNotesSuccess(response.data.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(getNotesFailure());
  }
}

export default all([takeLatest("@notes/GET_NOTES_REQUEST", getNotes)]);
