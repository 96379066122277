import React, { useEffect, useRef } from "react";
import { MdSearch } from "react-icons/md";

import { useTheme } from "@material-ui/core";
import { useField } from "@unform/core";
import PropTypes from "prop-types";

import { Container, Input } from "./styles";

export default function SearchBar({ type, name, ...rest }) {
  const theme = useTheme();
  const inputRef = useRef(null);
  const materialInputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.value = value;

        materialInputRef.current
          .querySelector("label")
          .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink");
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <Input
        type={type}
        name={fieldName}
        ref={materialInputRef}
        inputRef={inputRef}
        defaultValue={defaultValue}
        variant="outlined"
        {...rest}
      />
      <button type="submit" className="search-icon">
        <MdSearch color={theme.palette.primary[theme.palette.type]} size={25} />
      </button>
    </Container>
  );
}

SearchBar.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
};

SearchBar.defaultProps = {
  type: "text",
  name: "text",
};
