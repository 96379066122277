/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from "react";
import S3 from "react-aws-s3";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Player } from "video-react";
import "~/styles/video-react.css";

import { Loading, AccordionClassromModules } from "~/components";
import Comment from "~/components/Comment";
import FavoritesDrawer from "~/components/FavoritesDrawer";
import { ROUTES } from "~/constants/routes";
import {
  Avatar,
  IconLike,
  IconDislike,
  IconStar,
  Course,
  IconEmph,
  IconFavorites,
  IconNotes,
  IconStarFilled,
} from "~/images";
import {
  getCommentsRequest,
  postCommentRequest,
} from "~/store/modules/comments/actions";
import { getCourseRequest } from "~/store/modules/course/actions";
import {
  editFavoritesRequest,
  getFavoritesRequest,
  postFavoritesRequest,
} from "~/store/modules/favorites/actions";
import { postLikedislikeRequest } from "~/store/modules/likedislike/actions";
import { getModuleRequest } from "~/store/modules/module/actions";
// import { getVideoRequest } from "~/store/modules/videos/actions";
import { theme } from "~/styles/themes";
import { formatFileName } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";
import { capitalize } from "~/utils/stringUtils";

import {
  Container,
  NewComment,
  NewCommentOptions,
  NewCommentButtons,
  AddFiles,
  CommentsContainer,
  CommentsHeader,
  VideoContainer,
  VideoTools,
  Video,
  IconContainer,
  VideoToolsText,
  ButtonLike,
  Background,
  ClassTools,
  ButtonDislike,
  ButtonStar,
  Files,
  File,
  LeftMenu,
  BtnCallback,
  Content,
  MainHeader,
} from "./styles";

function ClassRoomPage() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "classroom.pageTitle" }));

  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const { modules } = useSelector((state) => state.modules);
  const { loading: likedislikeLoading } = useSelector(
    (state) => state.likedislikes
  );
  const { comments, loading: commentLoading } = useSelector(
    (state) => state.comments
  );
  const { favorites, loading: favoritesLoading } = useSelector(
    (state) => state.favorites
  );

  const [newCommentState, setNewCommentState] = useState("");
  const [commentsState, setCommentsState] = useState(null);
  const [courseState, setCourseState] = useState(null);
  const [modulesState, setModulesState] = useState(null);
  const [classState, setClassState] = useState(null);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [commentFiles, setCommentFiles] = useState(null);
  const [commentFilesName, setCommentFilesName] = useState(null);
  const [favorited, setFavorited] = useState(null);
  const [favoritesState, setFavoritesState] = useState(null);
  const [showFavoriteList, setShowFavoriteList] = useState(null);

  const { course_id } = useParams();

  async function handleText(data) {
    setNewCommentState(data.currentTarget.value);
  }

  // useEffect(() => {
  //   dispatch(
  //     getVideoRequest({
  //       file:
  //         "dmlkZW9zL2F1bGFzL2dlc3Rhb19lbXByZXNhcmlhbC9nZXN0YW9fZW1wcmVzYXJpYWxfMDEubXA0",
  //     })
  //   );
  // }, []);

  useEffect(() => {
    dispatch(getCourseRequest({ course_id }));
  }, [dispatch, course_id]);

  useMemo(() => {
    if (course) setCourseState(course);
  }, [course]);

  useMemo(() => {
    if (favorites) setFavoritesState(favorites);
  }, [favorites]);

  useEffect(() => {
    if (favoritesState && favoritesState.length > 0) {
      let favoriteIndex = favoritesState.findIndex(
        (fav) =>
          fav.disciplina[0].capitulo[0].aula[0].crs_aula.id_aula ===
          classState.crs_aula.id_aula
      );

      if (favoriteIndex !== -1) {
        setFavorited(
          favoritesState[favoriteIndex].mat_favorito_aula.tp_situacao === "A"
        );
      }
    }
  }, [favoritesState]);

  useMemo(() => {
    if (comments) setCommentsState(comments);
  }, [comments]);

  useMemo(() => {
    if (courseState && courseState.id_matricula)
      dispatch(
        getModuleRequest({
          id_matricula: courseState.id_matricula,
        })
      );
  }, [courseState]);

  useEffect(() => {
    if (modules.length > 0 && courseState && courseState.id_matricula) {
      setModulesState(modules);
      const currentClass = modules[0].classes[0].aulas[0];
      setClassState(currentClass);
      if (currentClass.mat_video_like.id_like === null) {
        setLiked(false);
        setDisliked(false);
      } else {
        setLiked(currentClass.mat_video_like.nr_like > 0);
        setDisliked(currentClass.mat_video_like.nr_dislike > 0);
      }

      dispatch(
        getCommentsRequest({
          id_aula: currentClass.crs_aula.id_aula,
          id_curso: course_id,
        })
      );
      dispatch(
        getFavoritesRequest({
          id_grade: modules[0].grade,
          id_matricula: courseState.id_matricula,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  let isReady = false;

  if (!isEmpty(courseState) && !isEmpty(modulesState)) {
    isReady = true;
  }

  return (
    <div>
      {!isReady ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <Container>
          <Background background={Course}>
            <div />
          </Background>

          <FavoritesDrawer
            show={showFavoriteList}
            closeList={setShowFavoriteList}
          />

          <LeftMenu>
            <BtnCallback to={ROUTES.MY_COURSES}>
              <span>
                <MdKeyboardArrowLeft
                  size={16}
                  color={theme.palette.primary[theme.palette.type]}
                />
              </span>
              <p>Voltar</p>
            </BtnCallback>

            <AccordionClassromModules items={modules} />
          </LeftMenu>

          <Content>
            <MainHeader>
              <h1>{capitalize(courseState.coursePreview.title)}</h1>
              <div>
                <p>
                  {courseState.banner.modules > 1
                    ? `${courseState.banner.modules} ${_e({
                        id: "course.modulesBannerPlural",
                      })}`
                    : `${courseState.banner.modules} ${_e({
                        id: "course.modulesBannerSingular",
                      })}`}
                </p>
                <p>{courseState.banner.duration}h de duração</p>
              </div>
            </MainHeader>
            <VideoContainer>
              <Video>
                <Player
                  fluid
                  playsInline
                  poster="/assets/poster.png"
                  src="https://videoiesde.secure.footprint.net/token=nva=1605295727576~dirs=3~hash=0ae5246589298beed1c2f/videos/aulas/gestao_empresarial/gestao_empresarial_01.mp4"
                />
              </Video>
              <VideoTools>
                <VideoToolsText>
                  <img src={Avatar} alt="Avatar de usuário" />
                  <p>Escreva um comentário_</p>
                </VideoToolsText>
                <IconContainer>
                  <div>
                    <ButtonLike
                      liked={liked}
                      onClick={() => {
                        if (!liked && !likedislikeLoading) {
                          setLiked(true);
                          setDisliked(false);
                          dispatch(
                            postLikedislikeRequest({
                              id_matricula: courseState.id_matricula,
                              id_video: classState.crs_video.id_video,
                              id_aula: classState.crs_aula.id_aula,
                              nr_like: 1,
                              nr_dislike: 0,
                            })
                          );
                        }

                        if (liked && !likedislikeLoading) {
                          setLiked(false);
                          dispatch(
                            postLikedislikeRequest({
                              id_matricula: courseState.id_matricula,
                              id_video: classState.crs_video.id_video,
                              id_aula: classState.crs_aula.id_aula,
                              nr_like: 0,
                              nr_dislike: 0,
                            })
                          );
                        }
                      }}
                    >
                      <img src={liked ? IconLike : IconDislike} alt="like" />
                    </ButtonLike>
                    <p>
                      {liked
                        ? classState.crs_video.nr_like + 1
                        : classState.crs_video.nr_like}
                    </p>
                  </div>

                  <div>
                    <ButtonDislike
                      disliked={disliked}
                      onClick={() => {
                        if (!disliked && !likedislikeLoading) {
                          setDisliked(true);
                          setLiked(false);
                          dispatch(
                            postLikedislikeRequest({
                              id_matricula: courseState.id_matricula,
                              id_video: classState.crs_video.id_video,
                              id_aula: classState.crs_aula.id_aula,
                              nr_like: 0,
                              nr_dislike: 1,
                            })
                          );
                        }

                        if (disliked && !likedislikeLoading) {
                          setDisliked(false);
                          postLikedislikeRequest({
                            id_matricula: courseState.id_matricula,
                            id_video: classState.crs_video.id_video,
                            id_aula: classState.crs_aula.id_aula,
                            nr_like: 0,
                            nr_dislike: 0,
                          });
                        }
                      }}
                    >
                      <img
                        src={disliked ? IconLike : IconDislike}
                        alt="dislike"
                      />
                    </ButtonDislike>
                    <p>
                      {disliked
                        ? classState.crs_video.nr_dislike + 1
                        : classState.crs_video.nr_dislike}
                    </p>
                  </div>

                  <ButtonStar
                    onClick={() => {
                      if (favorited !== null) {
                        setFavorited(!favorited);
                      }

                      if (favorited === null) {
                        dispatch(
                          postFavoritesRequest({
                            id_aula: classState.crs_aula.id_aula,
                            id_matricula: courseState.id_matricula,
                            id_grade: modulesState[0].grade,
                            tp_situacao: "A",
                          })
                        );
                        setFavorited(true);
                      } else if (!favorited) {
                        dispatch(
                          editFavoritesRequest({
                            id_aula: classState.crs_aula.id_aula,
                            id_matricula: courseState.id_matricula,
                            id_grade: modulesState[0].grade,
                            tp_situacao: "A",
                          })
                        );
                      } else if (favorited) {
                        dispatch(
                          editFavoritesRequest({
                            id_aula: classState.crs_aula.id_aula,
                            id_matricula: courseState.id_matricula,
                            id_grade: modulesState[0].grade,
                            tp_situacao: "I",
                          })
                        );
                      }
                    }}
                  >
                    <img
                      src={favorited ? IconStarFilled : IconStar}
                      alt="star"
                    />
                  </ButtonStar>
                </IconContainer>
              </VideoTools>
            </VideoContainer>

            <CommentsContainer>
              <CommentsHeader>
                <h1>Comentários</h1>
                <p>Compartilhe um comentário sobre esta aula</p>
              </CommentsHeader>
              {commentLoading ? (
                <div className="area-loadding">
                  <Loading />
                </div>
              ) : (
                <div>
                  <NewComment>
                    <div>
                      <img src={Avatar} alt="Avatar" />
                      <input
                        onChange={handleText}
                        placeholder="Adicionar um comentário público..."
                        type="text"
                      />
                      <NewCommentOptions>
                        <AddFiles>
                          <label htmlFor="file">Anexar</label>
                          <input
                            onChange={(event) => {
                              setCommentFilesName(
                                Array.from(event.currentTarget.files).map(
                                  (file, index) => ({
                                    oldName: file.name,
                                    newName: `${
                                      courseState.id_matricula
                                    }_${formatFileName(
                                      new Date()
                                    )}${index}.${file.name.split(".").pop()}`,
                                  })
                                )
                              );
                              setCommentFiles(event.currentTarget.files);
                            }}
                            id="file"
                            multiple
                            type="file"
                          />
                        </AddFiles>
                        <NewCommentButtons>
                          {/* <button type="button">
                      <span>CANCELAR</span>
                    </button> */}
                          <button
                            type="button"
                            onClick={() => {
                              if (newCommentState !== "" && !commentFiles) {
                                dispatch(
                                  postCommentRequest({
                                    ds_comentario: newCommentState,
                                    id_aula: classState.crs_aula.id_aula,
                                    id_curso: course_id,
                                  })
                                );
                              } else if (
                                newCommentState !== "" &&
                                commentFiles
                              ) {
                                const config = {
                                  // prettier-ignore
                                  bucketName: 'avaead-lms-dev',
                                  // prettier-ignore
                                  dirName: 'comentario',
                                  // prettier-ignore
                                  region: 'us-east-1',
                                  accessKeyId:
                                    process.env.REACT_APP_AWS_ACCESS_KEY,
                                  secretAccessKey:
                                    process.env.REACT_APP_AWS_SECRET_KEY,
                                  // prettier-ignore
                                  s3Url: 'https://avaead-lms-dev.s3.us-east-1.amazonaws.com/'
                                };

                                const ReactS3 = new S3(config);

                                Promise.all(
                                  Array.from(commentFiles).map((file, index) =>
                                    setTimeout(
                                      () => {
                                        return ReactS3.uploadFile(
                                          file,
                                          `${
                                            courseState.id_matricula
                                          }_${formatFileName(
                                            new Date()
                                          )}${index}`
                                        );
                                      },

                                      2000
                                    )
                                  )
                                )
                                  .then(() => {
                                    setCommentFiles(null);
                                    setCommentFilesName(null);
                                    dispatch(
                                      postCommentRequest({
                                        ds_comentario: newCommentState,
                                        id_aula: classState.crs_aula.id_aula,
                                        id_curso: course_id,
                                        data: commentFilesName.map(
                                          (fileName) => fileName.newName
                                        ),
                                      })
                                    );
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                    toast.error("Erro ao gravar anexo!");
                                  });
                              }
                            }}
                          >
                            <span>COMENTAR</span>
                          </button>
                        </NewCommentButtons>
                      </NewCommentOptions>
                    </div>

                    {commentFilesName && (
                      <Files>
                        {commentFilesName.map((file, index) => (
                          <File>
                            <span>{file.newName}</span>
                            <button
                              onClick={() => {
                                setCommentFiles(
                                  Array.from(commentFiles).filter(
                                    (crrFile) => file.oldName !== crrFile.name
                                  )
                                );
                                setCommentFilesName(
                                  commentFilesName.filter(
                                    (crrFileName) =>
                                      file.oldName !== crrFileName.oldName
                                  )
                                );
                              }}
                              type="button"
                            >
                              x
                            </button>
                          </File>
                        ))}
                      </Files>
                    )}
                  </NewComment>
                  {commentsState.map((comment) => (
                    <Comment
                      comment={comment}
                      id_aula={classState.crs_aula.id_aula}
                      id_curso={course_id}
                    />
                  ))}
                </div>
              )}
            </CommentsContainer>
          </Content>

          <ClassTools>
            <div>
              <p>Anotações</p>
              <p>Grifos</p>
              <p>Favoritos</p>
            </div>
            <div>
              <img src={IconNotes} alt="" />
              <img src={IconEmph} alt="" />
              <button
                type="button"
                onClick={() => setShowFavoriteList(!showFavoriteList)}
              >
                <img src={IconFavorites} alt="Favoritos" />
              </button>
            </div>
          </ClassTools>
        </Container>
      )}
    </div>
  );
}

export default ClassRoomPage;
