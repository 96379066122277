export function getAnnouncementsRequest({ from, to }) {
  return {
    type: "@announcements/GET_ANNOUNCEMENTS_REQUEST",
    payload: { from, to },
  };
}

export function getAnnouncementsSuccess(data) {
  return {
    type: "@announcements/GET_ANNOUNCEMENTS_SUCCESS",
    payload: { data },
  };
}

export function getAnnouncementsFailure(data) {
  return {
    type: "@announcements/GET_ANNOUNCEMENTS_FAILURE",
    payload: { data },
  };
}
