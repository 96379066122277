import React from "react";

import PropTypes from "prop-types";

export default function SearchResult({ categoryText, title, id }) {
  return (
    <li key={id}>
      <div className="previewImage" />
      <div className="content">
        <div className="category">{categoryText}</div>
        <div className="bottom-bar">
          <div className="title-result">{title}</div>
        </div>
      </div>
    </li>
  );
}

SearchResult.propTypes = {
  categoryText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
