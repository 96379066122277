import styled from "styled-components";

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text[theme.palette.type]};
  margin-bottom: 15px;
`;
