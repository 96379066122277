import React from "react";

import PropTypes from "prop-types";

import { Footer } from "~/components";
import { Logo } from "~/images";

import { Container } from "./styles";

/**
 * @todo alt em hardcode
 */
export default function AuthLayout({ children }) {
  return (
    <>
      <Container>
        <img src={Logo} alt="Ava - unipetros" className="logo-ava" />
        {children}
      </Container>
      <Footer />
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
