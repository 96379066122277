import { toast } from "react-toastify";

import { takeLatest, all, call, put } from "redux-saga/effects";

import { ROUTES, API } from "~/constants/routes";
import api from "~/services/api";
import history from "~/services/history";

import { signInSuccess, signFailure } from "./actions";

export function* signIn({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(api.post, API.LOGIN, {
      ds_email: email,
      ds_senha: password,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (response.status !== 200) throw new Error("Erro no login");

    yield put(signInSuccess(response.data));
    history.push(ROUTES.DASHBOARD);
  } catch (err) {
    toast.error(
      "Erro ao fazer o login, verifique seus dados e tente novamente!"
    );
    return yield put(signFailure());
  }
}

export default all([takeLatest("@auth/SIGN_IN_REQUEST", signIn)]);
