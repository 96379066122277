import produce from "immer";

const INITIAL_STATE = {
  login: null,
  signed: false,
  keep: false,
  loading: false,
  userInfo: {},
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@auth/SIGN_IN_SUCCESS": {
        const { data } = action.payload;

        draft.loading = false;
        draft.signed = true;
        draft.userInfo = {
          id_login: data[0].pes_login[0].id_login,
          id_matricula: data[0].pes_login[0].id_matricula,
          id_pessoa: data[0].pes_pessoa[0].id_pessoa,
          ds_nome: data[0].pes_pessoa[0].ds_nome.toLowerCase(),
        };
        break;
      }
      case "@auth/SIGN_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@auth/SIGN_OUT": {
        draft.token = null;
        draft.signed = false;
        draft.keep = false;
        draft.loading = false;
        draft.userInfo = {};

        break;
      }

      default:
    }
  });
}
