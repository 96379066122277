import styled from "styled-components";

import { IconWip } from "~/images";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: block;
  padding-bottom: 20px;
  height: 100%;
  .react-datepicker__month-container {
    float: initial;
  }
  .react-datepicker__month {
    * {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
  }

  .typeWip {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 50px;
    margin-right: 15px;
    background-image: url(${IconWip});
  }

  .react-datepicker__day {
    border-radius: 100px;
    width: 1.7rem;
    height: 1.7rem;
    text-align: center;
    padding: 5px 0;
    transition: all 0.2s ease;
    position: relative;
    .tooltip-mts {
      display: block;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) =>
        theme.palette.secondary[theme.palette.type]};
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: calc(100% + 10px);
      z-index: 50;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      background: #252525;
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2),
        0px 2px 4px rgba(0, 0, 0, 0.06);
      width: 260px;
      height: 420px;
      border-radius: 5px;
      padding: 20px;
      pointer-events: none;
      cursor: auto;
      &.hide {
        opacity: 0 !important;
        pointer-events: none !important;
        bottom: calc(100% + 10px) !important;
      }
      &:before {
        content: "";
        width: 25px;
        height: 15px;
        background: ${({ theme }) =>
          theme.palette.secondary[theme.palette.type]};
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        top: calc(100% - 2px);
        -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
      }
      .header-tooltip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        .title {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 16px;
          color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
        }
        .week-tool {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
          padding-left: 15px;
          border-left: 1px solid
            ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
        }
      }
      .content-tooltip {
        display: flex;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        flex: 1;
        height: calc(100% - 40px);
        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: ${({ theme }) =>
            theme.palette.textTwo[theme.palette.type]};
          border-radius: 100px;
        }
        ul {
          margin: 0;
          padding: 0;
          display: block;
          width: 100%;
          padding: 0 15px;
          li {
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            padding: 10px 0 20px;
            border-bottom: 1px solid
              ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
            &:last-child {
              border-bottom: 0px solid;
            }
            .title-cont {
              display: list-item;
              font-family: ${({ theme }) => theme.typography.fontFamily};
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              color: ${({ theme }) =>
                theme.palette.tertiary[theme.palette.type]};
            }
            p {
              font-family: ${({ theme }) => theme.typography.fontFamily};
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: normal;
              color: ${({ theme }) =>
                theme.palette.quaternary[theme.palette.type]};
            }
            * {
              color: ${({ theme }) =>
                theme.palette.tertiary[theme.palette.type]};
              text-align: left;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
    .day-span {
      background: transparent;
      outline: none;
      border: 0px solid;
    }
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
    color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    .day-span {
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    }
    .tooltip-mts {
      opacity: 1;
      pointer-events: all;
      bottom: calc(100% + 25px);
    }
  }

  .react-datepicker__month-container,
  .react-datepicker {
    display: block;
    width: 100%;
    border: 0px solid;
  }

  .react-datepicker__day--highlighted {
    background: transparent;
    position: relative;
    &:before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 100px;
      background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3px;
      transition: all 0.2s ease;
    }
    &.react-datepicker__day--selected {
      background: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      &:before {
        background: ${({ theme }) =>
          theme.palette.tertiary[theme.palette.type]};
      }
      &:after {
        opacity: 1;
      }
    }
  }
  .react-datepicker__week {
    margin-bottom: 10px;
    white-space: initial;
  }

  .react-datepicker__header {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: 0px solid;
  }
  .header-datepicker-mts {
    padding: 20px 40px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      line-height: normal;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
    .buttons-month {
      display: flex;
      button {
        border: 1px solid
          ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
        border-radius: 3px;
        background-color: transparent;
        margin-left: 5px;
        transition: all 0.4s linear;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  .react-datepicker__day-names {
    * {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: normal;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    }
  }
`;
