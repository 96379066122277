import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  passwordAltered: false,
};

export default function passwordReset(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@passwordReset/SEND_NEW_PASSWORD_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@passwordReset/SEND_NEW_PASSWORD_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@passwordReset/SEND_NEW_PASSWORD_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@passwordReset/PASSWORD_RECOVERY_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@passwordReset/PASSWORD_RECOVERY_SUCCESS": {
        draft.loading = false;
        draft.passwordAltered = true;
        break;
      }
      case "@passwordReset/PASSWORD_RECOVERY_FAILURE": {
        draft.loading = false;
        draft.passwordAltered = false;
        break;
      }

      default:
    }
  });
}
