import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useIntl } from "react-intl";

import { useTheme } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  getDate,
  getYear,
  getMonth,
  startOfDay,
  parseISO,
  format,
  isEqual,
} from "date-fns";
import pt from "date-fns/locale/pt";
import PropTypes from "prop-types";

import { LOCALE } from "~/constants/locale";
import browserLocale from "~/utils/browserLocale";

import { monthNames, monthNamesEN } from "./months.const";
import { Container } from "./styles";

import "react-datepicker/dist/react-datepicker.css";

registerLocale(LOCALE.PT, pt);

export default function Calendar({ selected, onChange, calendar }) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const isPT = browserLocale === LOCALE.PT_BR || browserLocale === LOCALE.PT;

  const { formatMessage: _e } = useIntl();

  const handleClickAway = () => {
    setOpen(false);
  };

  const highlightDates = calendar.map((item) => {
    return parseISO(item.date);
  });

  const renderDayContents = (day, date) => {
    const resetDays = highlightDates.map((item) => {
      return String(startOfDay(item));
    });

    let eventsDate = [];

    calendar.forEach((item) => {
      if (isEqual(startOfDay(parseISO(item.date)), startOfDay(date))) {
        const { events } = item;
        eventsDate = events;
      }
    });

    return (
      <>
        {resetDays.indexOf(String(startOfDay(date))) >= 0 && (
          <span className={`tooltip-mts ${!open && "hide"}`}>
            <div className="header-tooltip">
              <div className="title">
                {getDate(date)} {_e({ id: "global.of" })}{" "}
                {isPT
                  ? monthNames[getMonth(date)]
                  : monthNamesEN[getMonth(date)]}
              </div>
              <div className="week-tool">
                {format(date, "EEEE", { locale: isPT ? pt : false })}
              </div>
            </div>
            <div className="content-tooltip">
              <ul>
                {eventsDate.map((item, index) => (
                  <li key={String(index)}>
                    <div className="title-cont">{item.title}</div>
                    <p>{item.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </span>
        )}
        <button
          className="day-span"
          type="button"
          onClick={() => setOpen(true)}
        >
          {getDate(date)}
        </button>
      </>
    );
  };

  return (
    <Container>
      <ClickAwayListener onClickAway={handleClickAway}>
        <DatePicker
          inline
          useWeekdaysShort
          locale={isPT ? LOCALE.PT : browserLocale}
          selected={selected}
          onChange={onChange}
          highlightDates={highlightDates}
          renderDayContents={renderDayContents}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="header-datepicker-mts">
              <div className="title">
                {isPT
                  ? `${monthNames[getMonth(date)]} ${getYear(date)}`
                  : `${monthNamesEN[getMonth(date)]} ${getYear(date)}`}
              </div>
              <div className="buttons-month">
                <button onClick={decreaseMonth} type="button">
                  <MdKeyboardArrowLeft
                    color={theme.palette.primary[theme.palette.type]}
                  />
                </button>
                <button onClick={increaseMonth} type="button">
                  <MdKeyboardArrowRight
                    color={theme.palette.primary[theme.palette.type]}
                  />
                </button>
              </div>
            </div>
          )}
        />
      </ClickAwayListener>
      <div className={`typeWip ${calendar}`} />
    </Container>
  );
}

Calendar.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func,
  calendar: PropTypes.array,
};

Calendar.defaultProps = {
  selected: new Date(),
  onChange: () => {},
  calendar: [],
};
