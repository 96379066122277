import { Link } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  .area-loadding {
    display: block;
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .form-search {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
  }

  .search-options {
    width: 100%;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-results {
      display: flex;
      flex-direction: column;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
      .total {
        font-size: 16px;
        font-weight: bold;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      }
    }
    .filters {
      display: flex;
      .select-filter {
        margin-left: 20px;
      }
    }
  }

  ul.ul-search-results {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 100px;
    * {
      color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    }
    li {
      margin: 0;
      padding: 0;
      display: flex;
      margin: 17px 0;
      &:first-child {
        margin-top: 60px;
      }
      .previewImage {
        width: 220px;
        height: 100px;
        background-color: ${({ theme }) =>
          theme.palette.textTwo[theme.palette.type]};
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-left: 24px;
        .category {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.84375px;
          text-transform: uppercase;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        }
        .bottombar {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .title-result {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.25;
            color: ${({ theme }) =>
              theme.palette.secondary[theme.palette.type]};
          }
          .time {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 1.5;
            color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          }
        }
      }
    }
  }
`;

export const BtnCallback = styled(Link)`
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  position: absolute;
  left: 100px;
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme.palette.secondary[theme.palette.type]} !important;
  transition: opacity 0.2s ease;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
`;
