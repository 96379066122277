import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 75px;
    height: 75px;
  }
`;
