import { toast } from "react-toastify";

import { takeLatest, all, call, put } from "redux-saga/effects";

import { API } from "~/constants/routes";
import { intl } from "~/messages";
import api from "~/services/api";

import {
  sendNewPasswordSuccess,
  sendNewPasswordFailure,
  passwordRecoverySuccess,
  passwordRecoveryFailure,
} from "./actions";

export function* sendNewPassword({ payload }) {
  const { email } = payload;

  try {
    const response = yield call(api.post, API.PASSWORDRESET_REQUEST, {
      ds_email: email,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (response.data.errors[0])
      throw new Error(response.data.errors[0].message);

    toast.success(
      intl.formatMessage({ id: "login.newPasswordRequestedSuccessfully" })
    );
    return yield put(sendNewPasswordSuccess(response.data.data));
  } catch (err) {
    toast.error(intl.formatMessage({ id: "login.forgotYourPasswordError" }));
    return yield put(sendNewPasswordFailure());
  }
}

export function* passwordRecovery({ payload }) {
  const { password, token } = payload;

  try {
    const response = yield call(api.put, API.PASSWORDRESET_RECOVER, {
      ds_senha: password,
      token,
    });

    if (response.data.errors[0])
      throw new Error(response.data.errors[0].message);

    toast.success(
      intl.formatMessage({ id: "login.passwordUpdatedSuccessfully" })
    );
    return yield put(passwordRecoverySuccess(response.data.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(passwordRecoveryFailure());
  }
}

export default all([
  takeLatest("@passwordReset/SEND_NEW_PASSWORD_REQUEST", sendNewPassword),
  takeLatest("@passwordReset/PASSWORD_RECOVERY_REQUEST", passwordRecovery),
]);
