import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getNotifysSuccess, getNotifysFailure } from "./actions";

export function* getNotifys({ payload }) {
  const { from, to } = payload;

  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.get, API.NOTIFICATIONS_LIST, {
      id_login,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
      de: from,
      ate: to,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getNotifysSuccess(response.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(getNotifysFailure());
  }
}

export default all([takeLatest("@notifys/GET_NOTIFYS_REQUEST", getNotifys)]);
