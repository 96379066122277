import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 25px;
  .accordion-title {
    display: flex;
    align-items: center;
    .id {
      width: 12px;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: normal;
      color: ${({ theme }) => theme.palette.quinary[theme.palette.type]};
      margin-right: 15px;
      display: block;
    }
    h4 {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: normal;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      margin-right: 40px;
      margin-bottom: 0;
    }
    .discipline {
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 95px;
      height: 23px;
      background: ${({ theme }) => theme.palette.quinary[theme.palette.type]};
      border-radius: 3px;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: normal;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    }
  }
  .dateUnlock-container {
    display: flex;
    align-items: center;
  }
  .dateUnlock {
    border: 1px solid ${({ theme }) => theme.palette.senary[theme.palette.type]};
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    border-radius: 2px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    min-width: 90px;
    height: 25px;
    margin-right: 0.5rem;
    svg {
      margin-right: 10px;
    }
  }
  .classe-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    &:not(:last-child) {
      border-bottom: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    }
    a {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
    .info {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    }
    .dateUnlock {
      margin-right: 0;
    }
  }
`;

export const AccordionBase = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    marginBottom: -1,
    border: "0px solid",
    borderBottom: `1px solid
      ${theme.palette.quaternary[theme.palette.type]}`,
    padding: "0 20px",
    height: 70,
    "&$expanded": {
      height: 70,
    },
  },
  content: {
    margin: 0,
    justifyContent: "space-between",
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.senary[theme.palette.type],
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.textTwo[theme.palette.type],
  },
}))(MuiAccordionDetails);
