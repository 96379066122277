import React, { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { parseISO } from "date-fns";

import { Title, Loading, Pagination } from "~/components";
import { getNotesRequest } from "~/store/modules/notes/actions";
import { formatDateStandard } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, Note, NoteInfo } from "./styles";

function MyNotes() {
  const dispatch = useDispatch();
  const { notes, loading } = useSelector((state) => state.notes);

  const { formatMessage: _e } = useIntl();

  const [page, setPage] = useState(1);
  const [notesToDisplay, setNotesToDisplay] = useState([]);
  const [notesState, setNotesState] = useState([]);

  documentTitle(_e({ id: "notes.title" }));

  useMemo(() => {
    dispatch(getNotesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (notes && notes.length > 0)
      setNotesState(
        notes
          .map((note) => {
            return note.data.map((nt) => (
              <Note>
                <NoteInfo>
                  <p>{`${note.crs_curso.ds_curso.toLowerCase()} > ${note.crs_nivel.ds_nivel.toLowerCase()}`}</p>
                  <h3>{nt.mat_anotacao.ds_anotacao}</h3>
                  <span>
                    {formatDateStandard(parseISO(note.crs_curso.dt_cadastro))}
                  </span>
                </NoteInfo>
              </Note>
            ));
          })
          .reduce((curr, note) => curr.concat(note), [])
      );
  }, [notes]);

  useEffect(() => {
    setNotesToDisplay(
      notesState.slice(page > 1 ? (page - 1) * 5 : 0, page * 5)
    );
  }, [notesState, page]);

  function TitleMyNotes() {
    return `${_e({ id: "notes.title" })}`;
  }

  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <div className="title-container">
            <div>
              <Title>
                <TitleMyNotes />
              </Title>
              {/* <p>Lorem isum dolor</p> */}
            </div>

            {/* <Button>{_e({ id: "notes.createNote" })}</Button> */}
          </div>
          <div>{notesToDisplay}</div>
          <Pagination
            postsPerPage={5}
            totalPosts={notesState.length}
            setPaged={(number) => setPage(number)}
            currentPage={page}
          />
        </>
      )}
    </Container>
  );
}

export default MyNotes;
