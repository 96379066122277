import { Palette } from "./Palette";

export const palette = {
  type: "light",
  primary: new Palette("#5433F1", "#5433F1", "#5433F1", "#5433F1"),
  secondary: new Palette("#252525", "#252525", "#252525", "#252525"),
  tertiary: new Palette("#ffffff", "#ffffff", "#ffffff", "#ffffff"),
  quaternary: new Palette("#D9D9D9", "#D9D9D9", "#D9D9D9", "#D9D9D9"),
  quinary: new Palette("#03c2b7", "#03c2b7", "#03c2b7", "#03c2b7"),
  senary: new Palette("#efefef", "#efefef", "#efefef", "#efefef"),
  background: new Palette("#FBFBFB", "#FBFBFB", "#FBFBFB", "#FBFBFB"),
  backgroundTwo: new Palette("#D9D9D9", "#D9D9D9", "#D9D9D9", "#D9D9D9"),
  error: new Palette("#DC3545", "#DC3545", "#DC3545", "#DC3545"),
  text: new Palette("#252525", "#252525", "#252525", "#252525"),
  textTwo: new Palette("#7b7b7b", "#7b7b7b", "#7b7b7b", "#7b7b7b"),
  button: new Palette("#5433F1", "#5433F1", "#5433F1", "#5433F1"),
  textButton: new Palette("#ffffff", "#ffffff", "#ffffff", "#ffffff"),
};
