import { Link } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.palette.backgroundTwo[theme.palette.type]};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ background }) => background});
  .banner-content {
    width: 100%;
    min-height: 800px;
    padding: 50px 100px;
    display: flex;
    align-items: center;
    position: relative;
    backdrop-filter: blur(${({ blur }) => (blur ? "30px" : 0)});
  }
  h1 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    margin-bottom: 20px;
  }

  p {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    margin: 0;
    &.label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .content {
    display: flex;

    .title {
      text-transform: capitalize;
    }

    .course-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      .modules {
        display: flex;
        margin-right: 1.5rem;
      }
    }
  }
`;

export const BtnCallback = styled(Link)`
  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  position: absolute;
  left: 100px;
  top: ${({ bottom }) => (bottom ? "auto" : "70px")};
  bottom: ${({ bottom }) => (bottom ? "70px" : "auto")};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.tertiary} !important;
`;
