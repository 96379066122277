export function sendNewPasswordRequest({ email }) {
  return {
    type: "@passwordReset/SEND_NEW_PASSWORD_REQUEST",
    payload: { email },
  };
}

export function sendNewPasswordSuccess(data) {
  return {
    type: "@passwordReset/SEND_NEW_PASSWORD_SUCCESS",
    payload: data,
  };
}

export function sendNewPasswordFailure() {
  return {
    type: "@passwordReset/SEND_NEW_PASSWORD_FAILURE",
  };
}

export function passwordRecoveryRequest({ password, token }) {
  return {
    type: "@passwordReset/PASSWORD_RECOVERY_REQUEST",
    payload: { password, token },
  };
}

export function passwordRecoverySuccess(data) {
  return {
    type: "@passwordReset/PASSWORD_RECOVERY_SUCCESS",
    payload: data,
  };
}

export function passwordRecoveryFailure() {
  return {
    type: "@passwordReset/PASSWORD_RECOVERY_FAILURE",
  };
}
