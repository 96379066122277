import React from "react";

import PropTypes from "prop-types";

import { Footer, Header } from "~/components";

import { Container } from "./styles";

export default function DefaultLayout({ children }) {
  return (
    <Container>
      <Header />
      <div className="layout-content">{children}</div>
      <Footer />
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
