import React from "react";

import PropTypes from "prop-types";

import { AccordionModules } from "~/components";

import { Container } from "./styles";

export default function Modules({ title, description, modules }) {
  return (
    <Container>
      <h2>{title}</h2>
      <p>{description}</p>
      <AccordionModules items={modules} />
    </Container>
  );
}

Modules.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  modules: PropTypes.array,
};

Modules.defaultProps = {
  title: "",
  description: "",
  modules: [{}],
};
