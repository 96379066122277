import styled from "styled-components";

import { BrackgroundLogin } from "~/images";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 70px);
  align-items: center;
  background-image: url(${BrackgroundLogin});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  .logo-ava {
    margin: 0 auto;
    align-self: center;
    margin-bottom: 50px;
  }
`;
