const enUS = {
  title: "Announcements",
  description: "Check a summary of your activities.",
};

const ptBR = {
  title: "Comunicados",
  description: "Confira um resumo de suas atividades.",
};

export default { enUS, ptBR };
