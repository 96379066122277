import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContainerMD from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { Footer, Header, Sidebar, News } from "~/components";
import { getNewsRequest } from "~/store/modules/news/actions";
import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

export default function DefaultLayout({ children }) {
  const [show, setShow] = useState(true);

  const dispatch = useDispatch();
  const news = useSelector((state) => state.news);

  useEffect(() => {
    dispatch(getNewsRequest());
  }, [dispatch]);

  return (
    <Container>
      <Header />
      <div className="layout-content">
        <ContainerMD maxWidth="lg">
          <div className="account-content">
            {!isEmpty(news) && show && (
              <News
                date={news.date}
                description={news.description}
                link={news.link}
                onClose={() => setShow(false)}
              />
            )}
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <Sidebar />
              </Grid>
              <Grid item sm={9}>
                {children}
              </Grid>
            </Grid>
            {/* <div className="main-grid">
              <div className="sidebar-grid-area">
                <Sidebar />
              </div>
              <div className="content-grid-area">{children}</div>
            </div> */}
          </div>
        </ContainerMD>
      </div>
      <Footer />
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
