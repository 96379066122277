import styled from "styled-components";

import { BackNews } from "~/images";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 140px;
  background-color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-image: url(${BackNews});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 40px;
  padding: 30px;
  .left {
    display: flex;
    align-items: center;
    padding-right: 15px;
    min-width: 105px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) =>
        theme.palette.tertiary[theme.palette.type]};
      width: 41px;
      height: 41px;
      border-radius: 3px;
      margin-right: 15px;
    }
    .title {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    }
  }
  .middle {
    padding: 0 15px;
    flex: 1;
    .date {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 15px;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      margin-bottom: 10px;
    }
    .desc {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding-left: 15px;
    a {
      background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
      border: 2px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      box-sizing: border-box;
      border-radius: 3px;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      min-width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      text-decoration: none;
    }
    button {
      display: block;
      width: fit-content;
      height: fit-content;
      background: transparent;
      border: 0px solid;
    }
  }
`;
