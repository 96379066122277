/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { capitalize } from "@material-ui/core";
import { parseISO } from "date-fns";

import { Title, Loading } from "~/components";
import MyCoursesMenuButton from "~/components/MyCoursesMenuButton";
import MyCoursesPreview from "~/components/MyCoursesPreview";
import MyCoursesSortButton from "~/components/MyCoursesSortButton";
import { Preview } from "~/images";
import { getCourseRequest } from "~/store/modules/course/actions";
import documentTitle from "~/utils/documentTitle";
import { isEmpty } from "~/utils/object";

import {
  Container,
  ButtonsContainer,
  CoursesGrid,
  SortButtonContainer,
} from "./styles";

function MyCourses() {
  const dispatch = useDispatch();
  const {
    courses,
    courseLevels,
    finishedCourses,
    finishedCourseLevels,
  } = useSelector((state) => state.course);

  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "course.pageTitle" }));
  const [allCourses, setAllCourses] = useState(true);
  const [coursesLevelState, setCoursesLevelState] = useState("all");
  const [coursesToDisplay, setCoursesToDisplay] = useState([]);
  const [coursesState, setCoursesState] = useState([]);
  const [finishedCoursesState, setFinishedCoursesState] = useState([]);

  useMemo(() => {
    dispatch(getCourseRequest());
  }, [dispatch]);

  useEffect(() => {
    if (finishedCourses) setFinishedCoursesState(finishedCourses);
  }, [finishedCourses]);

  useEffect(() => {
    if (coursesState) setCoursesState(courses);
  }, [courses]);

  useEffect(() => {
    let typeOfCourses;
    if (allCourses) {
      typeOfCourses = coursesState;
    } else {
      typeOfCourses = finishedCoursesState;
    }

    if (coursesLevelState === "all") {
      setCoursesToDisplay(typeOfCourses);
    } else {
      setCoursesToDisplay(
        typeOfCourses.filter((crs) => crs.level === coursesLevelState)
      );
    }
  }, [allCourses, coursesState, finishedCoursesState, coursesLevelState]);

  function TitleMyCourses() {
    return `${_e({ id: "course.myCourses" })}`;
  }

  return (
    <Container>
      {isEmpty(courses) ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <div className="title-container">
            <div>
              <Title>
                <TitleMyCourses />
              </Title>
              <p>{_e({ id: "dashboard.summary" })}</p>
            </div>

            <ButtonsContainer>
              <MyCoursesMenuButton
                onClick={() => !allCourses && setAllCourses(true)}
                selected={allCourses}
                length={courses.length}
              >
                Cursando
              </MyCoursesMenuButton>
              <MyCoursesMenuButton
                disabled={finishedCourses.length < 1 && true}
                onClick={() => allCourses && setAllCourses(false)}
                length={finishedCourses.length}
                selected={!allCourses}
              >
                Concluídos
              </MyCoursesMenuButton>
            </ButtonsContainer>
          </div>
          <SortButtonContainer>
            <MyCoursesSortButton
              onClick={() =>
                coursesLevelState !== "all" && setCoursesLevelState("all")
              }
              selected={coursesLevelState === "all"}
              length={allCourses ? courses.length : finishedCourses.length}
            >
              Todos
            </MyCoursesSortButton>
            {allCourses
              ? courseLevels.map((level) => (
                  <MyCoursesSortButton
                    key={level}
                    onClick={() =>
                      coursesLevelState !== level && setCoursesLevelState(level)
                    }
                    selected={coursesLevelState === level}
                    length={courses.filter((crs) => crs.level === level).length}
                  >
                    {capitalize(level)}
                  </MyCoursesSortButton>
                ))
              : finishedCourseLevels.map((level) => (
                  <MyCoursesSortButton
                    key={level}
                    onClick={() =>
                      coursesLevelState !== level && setCoursesLevelState(level)
                    }
                    selected={coursesLevelState === level}
                    length={
                      finishedCourses.filter((crs) => crs.level === level)
                        .length
                    }
                  >
                    {capitalize(level)}
                  </MyCoursesSortButton>
                ))}
          </SortButtonContainer>
          <CoursesGrid>
            {coursesToDisplay.map((crs) => (
              <>
                <MyCoursesPreview
                  key={crs.id}
                  image={Preview}
                  category={crs.category}
                  title={crs.title}
                  progress={crs.progress}
                  url={crs.url}
                  startDate={parseISO(crs.startDate)}
                  endDate={parseISO(crs.endDate)}
                  situation={crs.situation}
                />
              </>
            ))}
          </CoursesGrid>
        </>
      )}
    </Container>
  );
}

export default MyCourses;
