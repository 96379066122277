const enUS = {
  finishedCourses: "Completed courses",
  coursesInProgress: "In progress",
  hoursStudied: "Hours studied",
  overallAverage: "Overall average",
};

const ptBR = {
  finishedCourses: "Cursos concluídos",
  coursesInProgress: "Em andamento",
  hoursStudied: "Horas estudadas",
  overallAverage: "Média geral",
};

export default { enUS, ptBR };
