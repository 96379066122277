import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  announcements: [],
  total: 0,
};

export default function announcements(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@announcements/GET_ANNOUNCEMENTS_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@announcements/GET_ANNOUNCEMENTS_SUCCESS": {
        const { data, total } = action.payload.data;

        const announcementsList = data.map(({ mat_comunicado, pes_pessoa }) => {
          return {
            id: mat_comunicado.id_comunicado,
            body: mat_comunicado.ds_comunicado,
            title: mat_comunicado.ds_titulo,
            date: mat_comunicado.dt_cadastro,
            link: `/comunicado/${mat_comunicado.id_comunicado}`,
            author: pes_pessoa.ds_nome,
          };
        });

        draft.loading = false;
        draft.announcements = announcementsList;
        draft.total = total.qtd_documento;
        break;
      }
      case "@announcements/GET_ANNOUNCEMENTS_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
