import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 260px;
  min-height: 60px;
  padding: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: ${({ isOpen }) => (isOpen ? "5px 5px 0 0" : "5px")};
  border: solid 2px;
  border-color: ${({ theme, isOpen }) =>
    isOpen
      ? theme.palette.primary[theme.palette.type]
      : theme.palette.quaternary[theme.palette.type]};
  background-color: ${({ theme }) =>
    theme.palette.tertiary[theme.palette.type]};
  border-bottom: ${({ isOpen }) => isOpen && "none"};
  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme, isOpen }) =>
      !isOpen && theme.palette.secondary[theme.palette.type]};
  }
  .label {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    line-height: normal;
  }
  .selected-value {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 800;
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
  }
  .select {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 35px 15px 20px;
    height: 56px;
    cursor: pointer;
    .dropdown-icon {
      position: absolute;
      right: 20px;
      top: auto;
      top: 50%;
      transform: translateY(-50%) ${({ isOpen }) => isOpen && "rotate(180deg)"};
      transition: transform 0.2s ease;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      height: 1.3em;
      width: 1.3em;
    }
  }
  .options {
    position: absolute;
    max-height: ${({ isOpen }) => (isOpen ? "none" : "0")};
    padding: 0;
    margin: 0;
    margin-top: 58px;
    list-style: none;
    box-shadow: inset 0 1px 0 0
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    width: calc(100% + 4px);
    left: -2px;
    border: solid 2px;
    border-color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
    border-top: none;
    border-radius: 0 0 5px 5px;
    top: -3px;
    background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    z-index: 1000;
    .option {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.21;
      letter-spacing: normal;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      padding: 7.5px 20px;
      cursor: pointer;
      &:first-child {
        margin-top: 10.5px;
      }
      &:hover,
      &:focus,
      &:active {
        opacity: 0.75;
      }
    }
  }
`;
