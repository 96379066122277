import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div``;

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    fontSize: 20,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  icon: {
    border: `2px solid ${theme.palette.quaternary[theme.palette.type]}`,
    borderRadius: 4,
    width: 20,
    height: 20,
    backgroundColor: "transparent",
    display: "flex",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary[theme.palette.type],
    border: `2px solid ${theme.palette.primary[theme.palette.type]}`,
    display: "flex",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControl: {
    margin: 0,
    "& .MuiTypography-root": {
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      color: theme.palette.textTwo[theme.palette.type],
      marginLeft: 10,
    },
  },
}));
