/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ContainerMD from "@material-ui/core/Container";
import { parseISO } from "date-fns";

import { Loading, Title } from "~/components";
import { ROUTES } from "~/constants/routes";
import { getAnnouncementsRequest } from "~/store/modules/announcements/actions";
import { formatDate } from "~/utils/date";
import documentTitle from "~/utils/documentTitle";

import { Container, BtnCallback } from "./styles";

export default function ViewAnnouncement() {
  const { formatMessage: _e } = useIntl();

  documentTitle(_e({ id: "announcements.title" }));

  const dispatch = useDispatch();
  const { loading, announcements } = useSelector(
    (state) => state.announcements
  );
  const [announcementToDisplay, setAnnouncementToDisplay] = useState(null);

  const theme = useTheme();
  let { id_comunicado } = useParams();

  useEffect(() => {
    dispatch(
      getAnnouncementsRequest({
        from: 1,
        to: 50,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setAnnouncementToDisplay(
      announcements.find((anc) => String(anc.id) === String(id_comunicado))
    );
  }, [announcements]);
  return (
    <Container>
      {loading ? (
        <div className="area-loadding">
          <Loading />
        </div>
      ) : (
        <>
          <BtnCallback to={ROUTES.ANNOUNCEMENTS}>
            <span>
              <MdKeyboardArrowLeft
                size={16}
                color={theme.palette.primary[theme.palette.type]}
              />
            </span>
            Voltar
          </BtnCallback>
          <ContainerMD maxWidth="md">
            <div className="content-wrapper">
              <div className="title-wrapper">
                <Title>{_e({ id: "announcements.title" })}</Title>
                <p>{_e({ id: "announcements.description" })}</p>
              </div>
              <div className="announcements-container">
                <ul className="ul-announcements">
                  {announcementToDisplay && (
                    <li key={String(announcementToDisplay.id)}>
                      <div className="content">
                        <div className="topbar">
                          {/* <div className="category">
                            {announcement.category}
                          </div> */}
                          <div className="title-announcements">
                            {announcementToDisplay.title}
                          </div>
                        </div>
                        <div className="author_container">
                          <div className="user_portrait" />
                          <div className="author_text">
                            <span>{announcementToDisplay.author}</span>
                            <span>
                              {formatDate(parseISO(announcementToDisplay.date))}
                            </span>
                          </div>
                        </div>
                        <div className="text_body">
                          {announcementToDisplay.body}
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </ContainerMD>
        </>
      )}
    </Container>
  );
}
