export function getNewsRequest() {
  return {
    type: "@news/GET_NEWS_REQUEST",
  };
}

export function getNewsSuccess(data) {
  return {
    type: "@news/GET_NEWS_SUCCESS",
    payload: { data },
  };
}
