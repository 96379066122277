import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Avatar } from "~/images";
import {
  deleteFileRequest,
  postCommentReplyRequest,
} from "~/store/modules/comments/actions";

import {
  Container,
  Top,
  CommentHeader,
  Bottom,
  Files,
  File,
  Options,
  ReplyContainer,
  CommentReplyContainer,
} from "./styles";

export default function Comment({ comment, id_aula, id_curso }) {
  const { id_login } = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();

  const [replyState, setReplyState] = useState(false);
  const [newReply, setNewReply] = useState("");

  async function handleText(data) {
    setNewReply(data.currentTarget.value);
  }

  return (
    <Container key={comment.crs_comentario_aula.id_comentario}>
      <Top>
        <CommentHeader>
          <img src={Avatar} alt="Avatar" />
          <div>
            <h3>{comment.pes_pessoa.ds_nome}</h3>
            <p className="post_date">
              Postou {comment.crs_comentario_aula.dt_cadastro}
            </p>
          </div>
        </CommentHeader>

        <p className="comment">{comment.crs_comentario_aula.ds_comentario}</p>

        {comment.resposta.length > 0 && (
          <CommentReplyContainer>
            {comment.resposta.map((resposta) => (
              <>
                <CommentHeader>
                  <img src={Avatar} alt="Avatar" />
                  <div>
                    <h3>{resposta.pes_pessoa.ds_nome}</h3>
                    <p className="post_date">
                      Postou {resposta.crs_comentario_resposta.dt_cadastro}
                    </p>
                  </div>
                </CommentHeader>

                <p className="comment">
                  {resposta.crs_comentario_resposta.ds_resposta}
                </p>
              </>
            ))}
          </CommentReplyContainer>
        )}
      </Top>

      <Bottom>
        <Files>
          {comment.arquivo.length > 0 &&
            comment.arquivo.map((arq) => (
              <File>
                <a
                  href={`${process.env.REACT_APP_API_FILE_URL}/${arq.crs_comentario_arquivo.ds_arquivo}`}
                >
                  {arq.crs_comentario_arquivo.ds_arquivo}
                </a>
                {comment.pes_login.id_login === id_login && (
                  <button
                    onClick={() =>
                      dispatch(
                        deleteFileRequest({
                          id_arquivo: arq.crs_comentario_arquivo.id_arquivo,
                        })
                      )
                    }
                    type="button"
                  >
                    x
                  </button>
                )}
              </File>
            ))}
        </Files>
        {replyState ? (
          <ReplyContainer>
            <input
              type="text"
              onChange={handleText}
              placeholder="Responda o comentário"
            />
            <button
              onClick={() => {
                setReplyState(false);
                dispatch(
                  postCommentReplyRequest({
                    id_comentario: comment.crs_comentario_aula.id_comentario,
                    ds_resposta: newReply,
                    id_curso,
                    id_aula,
                  })
                );
              }}
              type="button"
            >
              <span>RESPONDER</span>
            </button>
          </ReplyContainer>
        ) : (
          <Options>
            <button style={{ visibility: "hidden" }} type="button">
              Reportar
            </button>
            <button onClick={() => setReplyState(!replyState)} type="button">
              Responder
            </button>
          </Options>
        )}
      </Bottom>
    </Container>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  id_aula: PropTypes.number.isRequired,
  id_curso: PropTypes.number.isRequired,
};
