import styled from "styled-components";

import { IconAnswers2, IconConcluded, IconDownload, IconWip } from "~/images";

/**
 * @todo cor definida em hardcode na caracteristica box-shadow
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid
    ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .top {
    padding: 25px;
    .title {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      margin-bottom: 5px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 100%;
      height: 70px;
      margin-right: 60px;
      background-image: url(${IconWip});
    }
    .subtitle {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    }
  }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding: 25px;
      border-top: 1px solid
        ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
      .box-item {
        display: flex;
        align-items: center;
        max-width: 80%;
      }
      .icon {
        display: block;
        width: 50px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
        &.answer {
          background-image: url(${IconAnswers2});
        }
        &.concluded {
          background-image: url(${IconConcluded});
        }
        &.download {
          background-image: url(${IconDownload});
        }
      }
      .title {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
      }
      .date {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        min-width: 60px;
      }
      .text-link {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        text-decoration-line: underline;
        color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    padding: 25px;
    a {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      text-decoration-line: underline;
      color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
    }
  }
`;
