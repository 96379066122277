import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: transparent;
    border: none;
    height: fit-content;
    width: fit-content;
    outline: none;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
  }
  .btn-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: fit-content;
    height: fit-content;
    border: 0px solid;
    outline: none;
    background-color: transparent;
  }
`;

export const Input = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "normal",
    color: theme.palette.secondary[theme.palette.type],
    margin: 0,
    "& input": {
      padding: "7px 40px",
      height: 30,
      width: 500,
      backgroundColor: theme.palette.tertiary[theme.palette.type],
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "normal",
      color: theme.palette.secondary[theme.palette.type],
      borderRadius: 5,
      "&:placeholder": {
        fontFamily: theme.typography.fontFamily,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.palette.secondary[theme.palette.type],
      },
    },
  },
}))(TextField);
