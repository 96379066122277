import { createIntl } from "react-intl";

import browserLocale from "~/utils/browserLocale";
import { parseMessages } from "~/utils/parseMessages";

import messages from "./rootMessages";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/locale-data/pt");
  require("@formatjs/intl-pluralrules/locale-data/en");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/locale-data/pt");
  require("@formatjs/intl-relativetimeformat/locale-data/en");
}

if (!Intl.DisplayNames) {
  require("@formatjs/intl-displaynames/polyfill");
  require("@formatjs/intl-displaynames/locale-data/pt");
  require("@formatjs/intl-displaynames/locale-data/en");
}

const locale = messages[browserLocale] ? browserLocale : "pt-BR";

export const intl = createIntl({
  locale,
  messages: parseMessages(messages[locale]),
});
