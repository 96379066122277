import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { fakeApi } from "~/services/api";

import { getNewsSuccess } from "./actions";

export function* getNews() {
  try {
    const response = yield call(fakeApi.get, "news");

    yield put(getNewsSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([takeLatest("@news/GET_NEWS_REQUEST", getNews)]);
