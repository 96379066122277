import React from "react";
import { Route, Redirect } from "react-router-dom";

import PropTypes from "prop-types";

import { ROUTES } from "~/constants/routes";
import Account from "~/layouts/Account";
import AuthLayout from "~/layouts/Auth";
import DefaultLayout from "~/layouts/Default";
import { store } from "~/store";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAccount,
  ...rest
}) {
  const { signed } = store.getState().auth;

  let Layout = AuthLayout;

  if (!signed && (isPrivate || isAccount)) {
    return <Redirect to={ROUTES.DEFAULT} />;
  }

  if (signed && !isPrivate && !isAccount) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  if (isPrivate) {
    Layout = DefaultLayout;
  }

  if (isAccount) {
    Layout = Account;
  }

  if (!signed) {
    Layout = AuthLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAccount: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAccount: false,
};
