/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import {
  getFavoritesSuccess,
  getFavoritesFailure,
  postFavoritesSuccess,
  postFavoritesFailure,
  editFavoritesSuccess,
  editFavoritesFailure,
} from "./actions";

export function* getFavorites({
  payload,
  id_grade: id_grade_local,
  id_matricula: id_matricula_local,
}) {
  let id_grade;
  let id_matricula;

  if (payload === undefined) {
    id_grade = id_grade_local;
    id_matricula = id_matricula_local;
  } else {
    id_grade = payload.id_grade;
    id_matricula = payload.id_matricula;
  }

  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.get, API.FAVORITESUBJECTS_LIST, {
      id_login,
      id_grade,
      id_matricula,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getFavoritesSuccess(response.data.data[0].favorito));
  } catch (err) {
    toast.error(err.message);
    return yield put(getFavoritesFailure());
  }
}

export function* postFavorites({ payload }) {
  const { id_aula, id_grade, id_matricula, tp_situacao } = payload;

  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(api.post, API.FAVORITESUBJECTS_CREATE, {
      id_login,
      id_aula,
      id_grade,
      id_matricula,
      tp_situacao,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getFavorites({ id_grade, id_matricula });
    yield put(postFavoritesSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(postFavoritesFailure());
  }
}

export function* editFavorites({ payload }) {
  const { id_aula, id_grade, id_matricula, tp_situacao } = payload;

  try {
    const response = yield call(api.put, API.FAVORITESUBJECTS_EDIT, {
      id_aula,
      id_grade,
      id_matricula,
      tp_situacao,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield getFavorites({ id_grade, id_matricula });
    yield put(editFavoritesSuccess());
  } catch (err) {
    toast.error(err.message);
    return yield put(editFavoritesFailure());
  }
}

export default all([
  takeLatest("@favorites/GET_FAVORITES_REQUEST", getFavorites),
  takeLatest("@favorites/POST_FAVORITES_REQUEST", postFavorites),
  takeLatest("@favorites/EDIT_FAVORITES_REQUEST", editFavorites),
]);
