/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API, ROUTES } from "~/constants/routes";
import api, { fakeApi } from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getCourseSuccess, getLibrarySuccess } from "./actions";

export function* getCourse({ payload }) {
  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(fakeApi.get, "course");
    const responseCourse = yield call(api.get, API.COURSES_LIST, {
      id_login,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (responseCourse.data.errors.length)
      throw new Error(responseCourse.data.errors[0].message);

    const courses = responseCourse.data.data.map((crs) => {
      return {
        id: crs.crs_curso.id_curso,
        category: crs.crs_area.ds_area.toLowerCase(),
        level: crs.crs_nivel.ds_nivel.toLowerCase(),
        title: crs.crs_curso.ds_curso.toLowerCase(),
        progress: crs.crs_curso.progresso_curso,
        url: `${ROUTES.COURSE}/${crs.crs_curso.ds_curso.toLowerCase()}/${
          crs.crs_curso.id_curso
        }?id_mat=${crs.mat_matricula.id_matricula}`,
        startDate: crs.mat_matricula.dt_inicio,
        endDate: crs.mat_matricula.dt_fim,
        situation: crs.mat_situacao.ds_situacao.toLowerCase(),
      };
    });

    const courseLevels = responseCourse.data.data.map((crs) =>
      crs.crs_nivel.ds_nivel.toLowerCase()
    );
    const levelsSet = new Set(courseLevels);

    const finishedCourses = courses.filter(
      (cr) => cr.situation === "concluído"
    );
    const finishedLevelsSet = new Set(finishedCourses.map((crs) => crs.level));

    if (payload && payload.course_id) {
      const courseState = responseCourse.data.data.filter(
        (crs) => String(crs.crs_curso.id_curso) === payload.course_id
      );

      const {
        crs_area,
        crs_curso,
        mat_matricula,
        mat_situacao,
        mat_modulo,
      } = courseState[0];
      response.data.banner.title = crs_curso.ds_curso.toLowerCase();
      response.data.banner.image = crs_curso.ds_imagem_divulgacao;
      response.data.banner.description = crs_curso.ds_sobre;
      response.data.banner.duration = String(crs_curso.nr_duracao_horas);
      response.data.banner.modules = mat_modulo.qtd;
      response.data.banner.url = `${ROUTES.CLASSROOM}/${crs_curso.id_curso}`;

      response.data.id_matricula = mat_matricula.id_matricula;

      response.data.coursePreview = {
        category: crs_area.ds_area.toLowerCase(),
        title: crs_curso.ds_curso.toLowerCase(),
        progress: crs_curso.progresso_curso,
        url: `${ROUTES.COURSE}/${crs_curso.id}`,
        urlClass: `${ROUTES.CLASSROOM}/${crs_curso.id_curso}`,
        phasesTotal: 0,
        phasesComplete: 0,
        startDate: mat_matricula.dt_inicio,
        endDate: mat_matricula.dt_fim,
        situation: mat_situacao.ds_situacao.toLowerCase(),
        docsTotal: 0,
        docsComplete: 0,
        urlDocs: `${ROUTES.COURSE}/${crs_curso.ds_curso.toLowerCase()}/${
          mat_matricula.id_matricula
        }/biblioteca`,
        disciplineProgress: 0,
        disciplineTotal: 0,
        disciplineComplete: 0,
      };
    }

    yield put(
      getCourseSuccess({
        course: response.data,
        courses: courses.filter((cr) => cr.situation !== "concluído"),
        finishedCourses,
        courseLevels: Array.from(levelsSet).sort(),
        finishedCourseLevels: Array.from(finishedLevelsSet).sort(),
      })
    );
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export function* getLibrary() {
  const { id_matricula, id_login } = yield select(userInfo);

  try {
    const responseLibrary = yield call(api.get, API.COURSES_LIBRARY_LIST, {
      id_matricula,
      id_login,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });

    if (responseLibrary.data.errors.length)
      throw new Error(responseLibrary.data.errors[0].message);

    const libCourseSet = new Set(
      responseLibrary.data.data.map((lib) => lib.crs_curso.ds_curso)
    );

    const libCourseArray = Array.from(libCourseSet).map((lib) => ({
      value: lib,
      label: lib,
    }));

    yield put(
      getLibrarySuccess({
        library: responseLibrary.data.data,
        libraryCourses: libCourseArray,
      })
    );
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([
  takeLatest("@course/GET_COURSE_REQUEST", getCourse),
  takeLatest("@course/GET_LIBRARY_REQUEST", getLibrary),
]);
