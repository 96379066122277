const enUS = {
  notifications: "Notifications",
  viewAll: "View all",
  searchHere: "Search here_",
  myProfile: "My profile",
  financial: "Financial",
  settings: "Settings",
  exit: "Exit",
};

const ptBR = {
  notifications: "Notificações",
  viewAll: "Ver todas",
  searchHere: "Pesquise aqui_",
  myProfile: "Meu perfil",
  financial: "Financeiro",
  settings: "Configurações",
  exit: "Sair",
};

export default { enUS, ptBR };
