import React from "react";

import PropTypes from "prop-types";

import { Icon_pdf, Icon_link } from "../../images";
import { Container } from "./styles";

export default function LibraryLinkDisplay({ url, file }) {
  return (
    <Container file={file}>
      <img
        src={file ? Icon_pdf : Icon_link}
        alt={file ? "Ícone PDF" : "Ícone link"}
      />

      <div>
        <a href={file ? `${process.env.REACT_APP_API_FILE_URL}/${file}` : url}>
          {file ? "Download o PDF" : url}
        </a>
      </div>
    </Container>
  );
}

LibraryLinkDisplay.propTypes = {
  url: PropTypes.string,
  file: PropTypes.string,
};

LibraryLinkDisplay.defaultProps = {
  url: "/",
  file: "",
};
