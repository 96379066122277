import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Form } from "@unform/web";

import { Button, Input } from "~/components";
import { ROUTES } from "~/constants/routes";
import { Entrar } from "~/images";
import { sendNewPasswordRequest } from "~/store/modules/passwordReset/actions";
import documentTitle from "~/utils/documentTitle";
import { emailNewAccess } from "~/validators";

import { Container } from "./styles";

export default function SignIn() {
  const { formatMessage: _e } = useIntl();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.passwordReset);

  documentTitle(_e({ id: "login.forgotYourPassword" }));

  const form = useRef(null);

  async function handleSubmit(data) {
    const isValid = await emailNewAccess(data, form, _e);

    if (!isValid) {
      toast.error("E-mail inválido!");
      return;
    }

    dispatch(sendNewPasswordRequest({ email: data.email }));
  }

  /**
   * @todo alt e label fora da internacionalização
   * @todo remover bootstrap
   */
  return (
    <Container>
      <Form onSubmit={handleSubmit} ref={form}>
        <div className="d-flex justify-content-center align-items-center signin">
          <img src={Entrar} alt="Entrar" />{" "}
          {_e({ id: "login.forgotYourPassword" })}
        </div>
        <p>{_e({ id: "login.enterYourEmail" })}</p>
        <Input label="E-mail" name="email" type="email" required />
        <Button loading={loading} type="submit">
          {_e({ id: "login.requestNewAccess" })}
        </Button>
      </Form>
      <p className="text-center">
        <Link to={ROUTES.DEFAULT}>{_e({ id: "login.cancel" })}</Link>
      </p>
    </Container>
  );
}
