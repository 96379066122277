import styled from "styled-components";

/**
 * @todo box-shadow hardcode
 */
export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  form {
    display: block;
    width: 100%;
    max-width: 450px;
    border: 1px solid
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    border-radius: 15px;
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 60px;
    margin-bottom: 50px;
    .signin {
      display: block;
      margin-bottom: 30px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      color: ${({ theme }) => theme.palette.text[theme.palette.type]};
    }
    img {
      object-fit: contain;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
    button {
      margin-top: 50px;
    }
  }
`;
