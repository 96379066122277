import styled from "styled-components";

export const Container = styled.div`
  .title-container {
    margin-bottom: 3rem;
  }

  #icon_work {
    position: absolute;
    width: 35px;
    height: 35px;
  }

  .boxInfos {
    position: relative;
    #icon_work {
      right: 5px;
      bottom: 30px;
      z-index: 5;
    }
  }

  .user-status-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-auto-flow: column;
    width: 100%;
  }
  .info-grid {
    display: grid;
    grid-template-areas: "communications activities" "calendar activities";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(auto, max-content) auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    .communications-grid-area {
      grid-area: communications;
    }
    .calendar-grid-area {
      grid-area: calendar;
    }
    .activities-grid-area {
      position: relative;
      grid-area: activities;

      #icon_work {
        top: 25px;
        right: 25px;
      }
    }
  }
`;
