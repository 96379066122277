import React from "react";
import { MdPlayArrow } from "react-icons/md";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { formatDateStandard } from "~/utils/date";

import { Container } from "./styles";

export default function CoursePreview({
  image,
  category,
  title,
  progress,
  url,
  urlClass,
  phasesTotal,
  phasesComplete,
  startDate,
  endDate,
  situation,
  docsTotal,
  docsComplete,
  urlDocs,
  disciplineProgress,
  disciplineTotal,
  disciplineComplete,
  ...rest
}) {
  const theme = useTheme();

  const { formatMessage: _e } = useIntl();

  return (
    <Container {...rest}>
      <div className="preview" style={{ backgroundImage: `url(${image})` }}>
        <Link to={urlClass}>
          <MdPlayArrow
            size={20}
            color={theme.palette.primary[theme.palette.type]}
          />
        </Link>
      </div>
      <div className="course-preview-content">
        <div className="top">
          <div className="left">
            <div>
              <div className="category">{category}</div>
              <Link to={url} className="title">
                {title}
              </Link>
            </div>
            <div className="progress-area">
              <p>{_e({ id: "coursePreview.progress" })}</p>
              <div className="progress-mts">
                <span className="bar-bg">
                  <span className="bar" style={{ width: `${progress}%` }} />
                </span>
                <p className="porcent">
                  {progress}% {_e({ id: "coursePreview.concluded" })}
                </p>
              </div>
              {phasesTotal > 0 && (
                <p>
                  {phasesComplete} {_e({ id: "global.of" })} {phasesTotal}{" "}
                  {_e({ id: "coursePreview.phases" })}
                </p>
              )}
            </div>
          </div>
          <Link to={urlClass} className="continue">
            {_e({ id: "coursePreview.continue" })}
          </Link>
        </div>
        <div className="bottom">
          <div className="info-single">
            <div className="label">{_e({ id: "coursePreview.start" })}</div>
            <div className="text">{formatDateStandard(startDate)}</div>
          </div>
          <div className="info-single">
            <div className="label">
              {_e({ id: "coursePreview.termination" })}
            </div>
            <div className="text">{formatDateStandard(endDate)}</div>
          </div>
          <div className="info-single">
            <div className="label">{_e({ id: "coursePreview.situation" })}</div>
            <div className="text">{situation}</div>
          </div>
          <div className="info-single">
            <div className="label">
              {_e({ id: "coursePreview.completionDocs" })}
            </div>
            <div className="text">
              {docsComplete} {_e({ id: "global.of" })} {docsTotal}{" "}
              <Link to={urlDocs}>{_e({ id: "global.seeAll" })}</Link>
            </div>
          </div>
          <div className="info-single">
            <div className="label">{_e({ id: "coursePreview.subjects" })}</div>
            <div className="text">
              {disciplineProgress}% - {disciplineComplete}{" "}
              {_e({ id: "global.of" })} {disciplineTotal}{" "}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

CoursePreview.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  progress: PropTypes.number,
  url: PropTypes.string,
  urlClass: PropTypes.string,
  phasesTotal: PropTypes.number,
  phasesComplete: PropTypes.number,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  situation: PropTypes.string,
  docsTotal: PropTypes.number,
  docsComplete: PropTypes.number,
  urlDocs: PropTypes.string,
  disciplineProgress: PropTypes.number,
  disciplineTotal: PropTypes.number,
  disciplineComplete: PropTypes.number,
};

CoursePreview.defaultProps = {
  image: "",
  category: "",
  title: "",
  progress: 0,
  url: "/",
  urlClass: "/",
  phasesTotal: 0,
  phasesComplete: 25,
  startDate: new Date(),
  endDate: new Date(),
  situation: "",
  docsTotal: 0,
  docsComplete: 0,
  urlDocs: "",
  disciplineProgress: 0,
  disciplineTotal: 0,
  disciplineComplete: 0,
};
