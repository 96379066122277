import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ececec;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 230px;
  width: 807px;
  background-color: white;

  .comment {
    padding-left: 31px;
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 700px;
    height: auto;
  }

  img {
    width: 50px;
    height: 50px;
  }

  h3 {
    margin: 0;
    color: #1f1f1f;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 18px;
  }

  .post_date {
    font-size: 12px;
    margin-top: 2px;
  }

  p {
    margin: 0;
    margin-top: 20px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
  }
`;

export const CommentHeader = styled.div`
  padding-left: 31px;
  padding-top: 36px;
  padding-right: 31px;
  display: flex;
  align-content: center;

  div {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Options = styled.div`
  border-top: 1px solid #ececec;
  padding-top: 25px;
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;

  button {
    height: 15px;
    background: none;
    width: 100px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0;
    padding: 0;
    border: none;
    line-height: 15px;
  }

  button:first-of-type {
    padding-left: 31px;
    color: #1f1f1f;
  }
  button:last-of-type {
    color: #5433f1;
    padding-right: 31px;
    text-decoration: underline;
  }
`;

export const Top = styled.div``;

export const Bottom = styled.div``;

export const Files = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  margin-bottom: 20px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 25px;
  max-width: 200px;

  border-radius: 3px;
  background-color: #ececec;
  height: 25px;

  margin-top: 10px;
  margin-right: 10px;
  padding-left: 30px;

  a {
    text-decoration: none;
    margin: 0;
    color: #5433f1;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    width: 25px;
    font-weight: 600;
    line-height: 25px;
    color: #5433f1;
    border: none;
    height: 25px;
    text-align: center;
  }
`;

export const ReplyContainer = styled.div`
  border-top: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  input {
    width: 748px;
    border: none;
    border-bottom: 1px solid #979797;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
    margin-right: 27px;
    margin-bottom: 24px;
    margin-top: 40px;
  }

  button {
    margin-left: 22px;
    margin-right: 27px;
    margin-bottom: 27px;
    height: 40px;
    width: 163px;
    border-radius: 5px;
    border: none;
    background-color: #9a86f7;

    & > * {
      height: 15px;
      width: 75px;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
`;

export const CommentReplyContainer = styled.div`
  margin-left: 93px;
`;
