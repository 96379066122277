import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { formatDate } from "~/utils/date";

import { Container } from "./styles";

export default function BoxListSimple({ title, linkAll, data }) {
  const { formatMessage: _e } = useIntl();
  return (
    <Container>
      <div className="top">
        <div className="title">{title}</div>
        <Link to={linkAll}>{_e({ id: "global.seeAll" })}</Link>
      </div>
      <ul>
        {data.map((item, index) => (
          <li key={String(index)}>
            <div className="text-container">
              {/* <div className="category">{item.category}</div> */}
              <Link to={item.link} className="title">
                {item.title}
              </Link>
            </div>
            <div className="date">{formatDate(parseISO(item.date))}</div>
          </li>
        ))}
      </ul>
    </Container>
  );
}

BoxListSimple.propTypes = {
  title: PropTypes.string,
  linkAll: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

BoxListSimple.defaultProps = {
  title: "",
  linkAll: "/",
  data: [{}],
};
