import styled from "styled-components";

/**
 * @todo remover cores em hardcode
 */
export const Container = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 100px;
  position: relative;
  .tutors-title-container {
    display: flex;
    justify-content: space-between;
  }
  .slider-content {
    margin-top: 1.5rem;
  }
  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    margin-bottom: 10px;
  }
  .arrows-slick {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background: #ffffff;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 3px;
      width: 45px;
      height: 45px;
      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
  .slide-active {
    display: block;
    width: fit-content;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
  }
  .slick-active {
    .item-single {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    }
  }
  .item-single {
    background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 50px;
    width: calc(100% - 100px);
    display: flex;
    .left {
      display: block;
      width: 220px;
      .tutor-info {
        display: flex;
        align-items: center;
        img {
          display: block;
          width: 50px;
          height: 50px;
          object-fit: cover;
          margin-right: 20px;
        }
        .name {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        }
        .formation {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        }
      }
      .connect {
        display: block;
        width: 100%;
        position: relative;
        margin: 15px 0;
        &:before {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #dedede;
          position: absolute;
          left: 0;
          top: 50%;
        }
        span {
          font-family: ${({ theme }) => theme.typography.fontFamily};
          font-style: normal;
          font-weight: 300;
          font-size: 10px;
          line-height: 18px;
          color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
          margin: 15px 0;
          background-color: ${({ theme }) =>
            theme.palette.tertiary[theme.palette.type]};
          display: block;
          width: fit-content;
          margin: 0 auto;
          padding: 0 10px;
          z-index: 10;
          position: relative;
        }
      }
      .redes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 70px;
      }
    }
    .right {
      padding-left: 150px;
      align-self: center;
      flex: 1;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
    }
  }
`;
