import React from "react";
import { Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

import { Container, ButtonStyles } from "./styles";

export default function Button({ children, loading, to, ...rest }) {
  return (
    <Container>
      {to ? (
        <Link to={to} {...rest}>
          {loading ? <CircularProgress color="inherit" /> : children}
        </Link>
      ) : (
        <ButtonStyles {...rest}>
          {loading ? <CircularProgress color="inherit" /> : children}
        </ButtonStyles>
      )}
    </Container>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  loading: PropTypes.bool,
  to: PropTypes.string,
};

Button.defaultProps = {
  loading: false,
  to: "",
};
