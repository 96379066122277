import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import { MdKeyboardArrowDown, MdCheck } from "react-icons/md";
// import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { formatDateDayMonth } from "~/utils/date";

import {
  Container,
  AccordionTitle,
  AccordionBase,
  AccordionSummary,
  AccordionDetails,
  DisciplineProgress,
  FirstLine,
  ClassContainer,
  ClassLine,
  // GreenCheckbox,
} from "./styles";

export default function AccordionClassromModules({ items, ...rest }) {
  const theme = useTheme();
  const [expandedModule, setExpandedModule] = useState("");
  const [expandedDiscipline, setExpandedDiscipline] = useState("");
  const handleChangeModule = (panel) => (event, newExpanded) => {
    setExpandedModule(newExpanded ? panel : false);
  };
  const handleChangeDiscipline = (panel) => (event, newExpanded) => {
    setExpandedDiscipline(newExpanded ? panel : false);
  };

  return (
    <Container>
      {items.map((item, index) => (
        <AccordionBase
          type="module"
          key={String(index)}
          expanded={expandedModule === `panel${index}`}
          onChange={handleChangeModule(`panel${index}`)}
          {...rest}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            expandIcon={
              <MdKeyboardArrowDown
                className="accordion-chevron"
                color={
                  expandedModule === `panel${index}`
                    ? theme.palette.tertiary[theme.palette.type]
                    : theme.palette.primary[theme.palette.type]
                }
                size={20}
                strokeWidth={1.5}
              />
            }
          >
            <AccordionTitle
              type="module"
              expanded={expandedModule === `panel${index}`}
            >
              <div className="module-status">
                <h3 className="module-number">Módulo {index + 1}</h3>
                {true && (
                  <MdCheck
                    color={theme.palette.quinary[theme.palette.type]}
                    size={10}
                    strokeWidth={4}
                  />
                )}
              </div>
              <h4 className="module-name">{item.title}</h4>
              <div className="discipline">
                <span className="quantity">0/{item.discipline}</span>
                &nbsp;disciplinas concluídas
              </div>
            </AccordionTitle>
            <div className="dateUnlock-container">
              {item.dateUnlock && (
                <div className="dateUnlock">
                  <FaLock size="12px" />
                  {formatDateDayMonth(parseISO(item.dateUnlock))}
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {item.classes &&
              item.classes.map((classItem, indexClass) => (
                <AccordionBase
                  key={String(indexClass)}
                  expanded={expandedDiscipline === `panel${indexClass}`}
                  onChange={handleChangeDiscipline(`panel${indexClass}`)}
                  {...rest}
                >
                  <AccordionSummary
                    aria-controls={`panel${indexClass}d-content`}
                    id={`panel${indexClass}d-header`}
                    expandIcon={
                      <MdKeyboardArrowDown
                        className="accordion-chevron"
                        color={theme.palette.primary[theme.palette.type]}
                        size={20}
                        strokeWidth={1.5}
                      />
                    }
                  >
                    <AccordionTitle
                      expanded={expandedDiscipline === `panel${indexClass}`}
                    >
                      <FirstLine>
                        {/* <GreenCheckbox
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                        /> */}
                        <div>
                          <div className="module-status">
                            <h3 className="module-number">
                              Disciplina {indexClass + 1}
                            </h3>
                            {false && (
                              <MdCheck
                                color={
                                  theme.palette.quinary[theme.palette.type]
                                }
                                size={10}
                                strokeWidth={4}
                              />
                            )}
                          </div>
                          <h4 className="module-name">
                            {classItem.crs_disciplina.ds_disciplina}
                          </h4>
                        </div>
                      </FirstLine>

                      <DisciplineProgress
                        percent={classItem.crs_disciplina.progresso_disciplina}
                      >
                        <span className="percent">{`${classItem.crs_disciplina.progresso_disciplina}%`}</span>
                        <span className="bar-bg">
                          <span
                            className="bar"
                            style={{
                              width: `${classItem.crs_disciplina.progresso_disciplina}%`,
                            }}
                          />
                        </span>
                      </DisciplineProgress>
                    </AccordionTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {classItem.aulas.length > 0 &&
                      classItem.aulas.map((aula, indexAula) => (
                        <ClassContainer>
                          <ClassLine>
                            {/* <GreenCheckbox
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            /> */}
                            <div>
                              <span>
                                Aula {indexAula}: {aula.crs_aula.ds_aula}
                              </span>
                              <p>{aula.crs_video.ds_duracao_hora} horas</p>
                            </div>
                          </ClassLine>
                        </ClassContainer>
                      ))}
                  </AccordionDetails>
                </AccordionBase>
              ))}
          </AccordionDetails>
        </AccordionBase>
      ))}
    </Container>
  );
}

AccordionClassromModules.propTypes = {
  items: PropTypes.array.isRequired,
};
