import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  display: grid;
  min-height: 100vh;
  grid-template-areas:
    "layoutHeader"
    "layoutContent"
    "layoutFooter";
  grid-template-rows: 90px 1fr 70px;
  .layout-content {
    grid-area: layoutContent;
  }
`;
