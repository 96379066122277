import * as Yup from "yup";

export default async function emailNewAccess(data, formRef, _e) {
  try {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      email: Yup.string()
        .email()
        .required(_e({ id: "validators.requiredField" })),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    return true;
  } catch (err) {
    return false;
  }
}
