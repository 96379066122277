import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 100px;
  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    margin-bottom: 10px;
  }
`;
