import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API, ROUTES } from "~/constants/routes";
import api, { fakeApi } from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getDashboardSuccess } from "./actions";

export function* getDashboard() {
  const { id_login } = yield select(userInfo);

  try {
    const response = yield call(fakeApi.get, "dashboard");
    const dashboardResponse = yield call(api.get, API.DASHBOARD_LIST, {
      id_login,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });
    const {
      crs_disciplina,
      crs_area,
      mat_situacao,
      mat_matricula,
      mat_documento,
      crs_curso,
      crs_documento_instrucao,
    } = dashboardResponse.data.data[0];

    const coursePreview = {
      category: crs_area.ds_area.toLowerCase(),
      title: crs_curso.ds_curso.toLowerCase(),
      progress: crs_curso.progresso_curso,
      url: `${ROUTES.COURSE}/${crs_curso.ds_curso.toLowerCase()}/${
        crs_curso.id_curso
      }?id_mat=${mat_matricula.id_matricula}`,
      urlClass: `${ROUTES.CLASSROOM}/${crs_curso.id_curso}`,
      phasesTotal: 0,
      phasesComplete: 0,
      startDate: mat_matricula.dt_inicio,
      endDate: mat_matricula.dt_fim,
      situation: mat_situacao.ds_situacao.toLowerCase(),
      docsTotal: crs_documento_instrucao.qtde,
      docsComplete: mat_documento.qtde,
      urlDocs: `${ROUTES.COURSE}/${crs_curso.ds_curso.toLowerCase()}/${
        mat_matricula.id_matricula
      }/biblioteca`,
      disciplineProgress: crs_disciplina.progresso_disciplinas,
      disciplineTotal: crs_disciplina.qtde,
      disciplineComplete: crs_disciplina.qtde_concluido,
    };
    response.data.coursePreview = coursePreview;

    yield put(getDashboardSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([
  takeLatest("@dashboard/GET_DASHBOARD_REQUEST", getDashboard),
]);
