export function getNotesRequest() {
  return {
    type: "@notes/GET_NOTES_REQUEST",
  };
}

export function getNotesSuccess(data) {
  return {
    type: "@notes/GET_NOTES_SUCCESS",
    payload: { data },
  };
}

export function getNotesFailure() {
  return {
    type: "@notes/GET_NOTES_FAILURE",
  };
}
