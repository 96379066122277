import React from "react";

import PropTypes from "prop-types";

import { Accordion } from "~/components";

import { Container } from "./styles";

export default function Questions({ title, description, questions }) {
  return (
    <Container>
      <h2>{title}</h2>
      <p>{description}</p>
      <Accordion items={questions} />
    </Container>
  );
}

Questions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.array,
};

Questions.defaultProps = {
  title: "",
  description: "",
  questions: [{}],
};
