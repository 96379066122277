export function postLikedislikeRequest(payload) {
  return {
    type: "@likedislike/POST_LIKEDISLIKE_REQUEST",
    payload,
  };
}

export function postLikedislikeSuccess() {
  return {
    type: "@likedislike/POST_LIKEDISLIKE_SUCCESS",
  };
}

export function postLikedislikeFailure() {
  return {
    type: "@likedislike/POST_LIKEDISLIKE_FAILURE",
  };
}

export function putLikedislikeRequest(payload) {
  return {
    type: "@likedislike/PUT_LIKEDISLIKE_REQUEST",
    payload,
  };
}

export function putLikedislikeSuccess() {
  return {
    type: "@likedislike/PUT_LIKEDISLIKE_SUCCESS",
  };
}

export function putLikedislikeFailure() {
  return {
    type: "@likedislike/PUT_LIKEDISLIKE_FAILURE",
  };
}
