import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import { parseISO, isBefore } from "date-fns";
import PropTypes from "prop-types";

import { formatDateStandard } from "~/utils/date";

import {
  Container,
  AccordionBase,
  AccordionSummary,
  AccordionDetails,
} from "./styles";

export default function AccordionModules({ items, ...rest }) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container>
      {items.map((item, index) => (
        <AccordionBase
          key={String(index)}
          square
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          {...rest}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            expandIcon={
              <MdKeyboardArrowDown
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            }
          >
            <div className="accordion-title">
              <div className="id">{item.number}.</div>
              <h4>{item.title}</h4>
              <div className="discipline">{item.discipline} disciplinas</div>
            </div>
            <div className="dateUnlock-container">
              {isBefore(new Date(), parseISO(item.dateUnlock)) && (
                <div className="dateUnlock">
                  <FaLock />
                  {formatDateStandard(parseISO(item.dateUnlock))}
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {item.classes &&
              item.classes.map(({ crs_disciplina, aulas }, indexClass) => (
                <div className="classe-single" key={String(indexClass)}>
                  <div>
                    <Link
                      to={
                        !item?.dateUnlock
                          ? `/modulo/${item.id}/disciplina/${crs_disciplina.id_disciplina}`
                          : "#!"
                      }
                    >
                      {`${indexClass + 1}. ${crs_disciplina.ds_disciplina}`}
                    </Link>
                    <div className="info">{`${aulas.length} aulas`}</div>
                  </div>
                  {isBefore(
                    new Date(),
                    parseISO(crs_disciplina.dateUnlock)
                  ) && (
                    <div className="dateUnlock">
                      <FaLock />
                      {formatDateStandard(parseISO(crs_disciplina.dateUnlock))}
                    </div>
                  )}
                </div>
              ))}
          </AccordionDetails>
        </AccordionBase>
      ))}
    </Container>
  );
}

AccordionModules.propTypes = {
  items: PropTypes.array.isRequired,
};
