import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .conteudo-curso {
    display: block;
    width: 100%;
    height: 1000px;
  }
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-container {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
  .course-preview-wrapper {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 27px;
    margin-bottom: 19px;
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 21px;
  grid-template-rows: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const SortButtonContainer = styled.div`
  margin-bottom: 42px;
`;
