import api, { sessionApi } from "~/services/api";

import { isObj } from "./object";

export default async function generateSession() {
  api.addAsyncRequestTransform((request) => async () => {
    const response = await sessionApi.get("/");

    if (response.status !== 200)
      throw new Error("Não foi possível criar o token de sessão");

    const { sessionid } = response.data;
    request.headers.sessionid = sessionid;
    api.setHeader("sessionid", sessionid);
  });
  api.addResponseTransform((response) => {
    if (!isObj(response.data) && typeof response.data === "string") {
      try {
        response.data = JSON.parse(response.data);
        response.data.data =
          typeof response.data.data === "string"
            ? JSON.parse(response.data.data)
            : response.data.data;
        response.data.errors =
          typeof response.data.errors === "string"
            ? JSON.parse(response.data.errors)
            : response.data.errors;
      } catch (e) {
        return false;
      }
    }
  });
}
