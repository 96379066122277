const INITIAL_STATE = [];

export default function pages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@pages/GET_PAGES_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}
