import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  comments: [],
};

export default function comments(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@comments/GET_COMMENTS_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@comments/GET_COMMENTS_SUCCESS": {
        const { data } = action.payload.data;

        draft.loading = false;
        draft.comments = data;
        break;
      }
      case "@comments/GET_COMMENTS_FAILURE": {
        draft.loading = false;
        break;
      }

      case "@comments/POST_COMMENT_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@comments/POST_COMMENT_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@comments/POST_COMMENT_FAILURE": {
        draft.loading = false;
        break;
      }

      case "@comments/DELETE_FILE_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@comments/DELETE_FILE_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@comments/DELETE_FILE_FAILURE": {
        draft.loading = false;
        break;
      }

      case "@comments/POST_COMMENT_REPLY_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@comments/POST_COMMENT_REPLY_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@comments/POST_COMMENT_REPLY_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
