import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  modules: [],
};

export default function module(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@module/GET_MODULE_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@module/GET_MODULE_SUCCESS": {
        draft.loading = false;
        draft.modules = action.payload.data;
        break;
      }
      case "@module/GET_MODULE_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
