export function getDashboardRequest() {
  return {
    type: "@dashboard/GET_DASHBOARD_REQUEST",
  };
}

export function getDashboardSuccess(data) {
  return {
    type: "@dashboard/GET_DASHBOARD_SUCCESS",
    payload: { data },
  };
}
