import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { formatDate } from "~/utils/date";

import { Container } from "./styles";

export default function BoxListLarge({ title, subtitle, linkAll, data }) {
  const { formatMessage: _e } = useIntl();
  return (
    <Container>
      <div>
        <div className="top">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <ul>
          {data.map((item, index) => (
            <li key={String(index)}>
              <div className="box-item">
                <div className={`icon ${item.type}`} />
                <div>
                  <div className="title">{item.title}</div>
                  <Link to={item.link} className="text-link">
                    {item.textLink}
                  </Link>
                </div>
              </div>
              <div className="date">{formatDate(parseISO(item.date))}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className="bottom">
        <Link to={linkAll}>{_e({ id: "global.viewOlder" })}</Link>
      </div>
    </Container>
  );
}

BoxListLarge.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkAll: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

BoxListLarge.defaultProps = {
  title: "",
  subtitle: "",
  linkAll: "/",
  data: [{}],
};
