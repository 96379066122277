import styled from "styled-components";

export const Container = styled.div`
  .title-container {
    display: flex;
    justify-content: space-between;
  }
  .area-loading {
    display: block;
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LibraryItemsGrid = styled.div`
  margin-top: 46px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 29px;
  grid-template-rows: auto;
`;

export const LibraryItem = styled.div`
  height: 250px;
  width: 285px;
  border: 1px solid #ececec;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .info {
    margin: 45px 20px 0 36px;
  }

  .link {
    margin-top: 20px;
    margin-bottom: 26px;
    margin-left: 36px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;

    hr {
      height: 0.1px;
      width: 100%;
      border-bottom: 0.3px solid #979797;
      opacity: 0.2;
      background-color: #d8d8d8;
    }
  }

  h3 {
    margin-bottom: 20px;
    height: 19px;
    color: #2f2f2f;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  }

  p {
    height: 40px;
    width: 232px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
  }
`;
