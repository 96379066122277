import styled from "styled-components";

import { LogoMessages } from "../../images/index";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    margin: 0;
  }
  .banner-container {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
  .course-preview-wrapper {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  .message_icon {
    background-image: url(${LogoMessages});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 27px;
    margin-bottom: 19px;
    button {
      height: 50px;
      width: 215px;
    }

    button > * {
      height: 20px;
      width: 125px;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
`;

export const Note = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 5px;
  height: 110px;
  border: 1px solid #ececec;

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 5px;
    color: #1f1f1f;
  }
`;

export const NoteInfo = styled.div`
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 22px;

  span {
    height: 15px;
    width: 70px;
    color: #6c6c6c;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
  }
`;
