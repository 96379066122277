import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from "react-icons/md";
import { useIntl } from "react-intl";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { Button } from "~/components";
import { ROUTES, ANCHOR } from "~/constants/routes";
import { scrollDown } from "~/utils/scrollTop";

import { Container, BtnCallback } from "./styles";

export default function BannerCourse({
  // ratting,
  // stars,
  title,
  modules,
  duration,
  description,
  url,
  image,
  blur,
}) {
  const theme = useTheme();

  function handleAbout() {
    scrollDown(900, 100);
  }

  const { formatMessage: _e } = useIntl();

  return (
    <Container background={image} blur={blur}>
      <div className="banner-content">
        <BtnCallback to={ROUTES.DASHBOARD}>
          <span>
            <MdKeyboardArrowLeft
              size={16}
              color={theme.palette.primary[theme.palette.type]}
            />
          </span>
          {_e({ id: "course.back" })}
        </BtnCallback>
        <div className="content">
          {/* <div className="avaliation-wrapper">
            <Avaliation stars={stars} className="avaliation" />
            <p className="label">{ratting}</p>
          </div> */}
          <h1 className="title">{title}</h1>
          <div className="course-info">
            <p className="label modules">
              {modules > 1
                ? `${modules} ${_e({ id: "course.modulesBannerPlural" })}`
                : `${modules} ${_e({ id: "course.modulesBannerSingular" })}`}
            </p>
            <p className="label">
              {_e({ id: "course.duration" })} {duration}h
            </p>
          </div>
          <p className="description">{description}</p>
          <Button to={url}>{_e({ id: "course.continue" })}</Button>
        </div>
        <BtnCallback
          bottom="true"
          onClick={handleAbout}
          to={ANCHOR.ABOUT_COURSE}
        >
          <span>
            <MdKeyboardArrowDown
              size={16}
              color={theme.palette.primary[theme.palette.type]}
            />
          </span>
          {_e({ id: "course.aboutCourse" })}
        </BtnCallback>
      </div>
    </Container>
  );
}

BannerCourse.propTypes = {
  // ratting: PropTypes.number,
  // stars: PropTypes.number,
  title: PropTypes.string,
  modules: PropTypes.number,
  duration: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  blur: PropTypes.bool,
};

BannerCourse.defaultProps = {
  // ratting: 0,
  // stars: 0,
  title: "",
  modules: 0,
  duration: "",
  description: "",
  url: "",
  image: "",
  blur: false,
};
