import React, { useEffect, useRef } from "react";

import { useField } from "@unform/core";
import PropTypes from "prop-types";

import { Container, Input } from "./styles";

export default function InputTheme({ label, type, required, name, ...rest }) {
  const inputRef = useRef(null);
  const materialInputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.value = value;

        materialInputRef.current
          .querySelector("label")
          .classList.add("MuiFormLabel-filled", "MuiInputLabel-shrink");
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <Input
        type={type}
        required={required}
        variant="outlined"
        name={fieldName}
        error={!!error}
        ref={materialInputRef}
        helperText={error || null}
        inputRef={inputRef}
        defaultValue={defaultValue}
        label={label}
        fullWidth
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </Container>
  );
}

InputTheme.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

/**
 * @todo valor default campo fora da internacionalização
 */
InputTheme.defaultProps = {
  label: "Campo",
  type: "text",
  name: "text",
  required: false,
};
