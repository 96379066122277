const enUS = {
  logIn: "Log in",
  password: "Password",
  remember: "Remember",
  forgotYourPassword: "Forgot your password?",
  forgotYourPasswordError: "Error requesting new password.",
  firstAccess: "First access?",
  createAnAccount: "Create an account",
  requestNewAccess: "Request new access",
  enterYourEmail:
    "Enter your email and we will send you a link to create a new password.",
  cancel: "Cancel",
  send: "Send",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  passwordStrength: "Password strength",
  backToSite: "Back to site",
  passwordUpdatedSuccessfully: "Password updated successfully",
  newPasswordRequestedSuccessfully: "Password reset requested successfully",
  resetYourPassword: "Reset your password",
};

const ptBR = {
  logIn: "Entrar",
  password: "Senha",
  remember: "Lembrar-me",
  forgotYourPassword: "Esqueceu sua senha?",
  forgotYourPasswordError: "Erro ao requisitar nova senha.",
  firstAccess: "Primeiro acesso?",
  createAnAccount: "Crie uma conta",
  requestNewAccess: "Solicitar novo acesso",
  enterYourEmail:
    "Insira seu email que enviaremos um link para criar uma nova senha de acesso.",
  cancel: "Cancelar",
  send: "Enviar",
  newPassword: "Nova senha",
  confirmPassword: "Confirmar senha",
  passwordStrength: "Força da senha",
  backToSite: "Voltar para o site",
  passwordUpdatedSuccessfully: "Senha atualizada com sucesso",
  newPasswordRequestedSuccessfully:
    "Redefinição de senha solicitada com sucesso",
  resetYourPassword: "Redefina sua senha",
};

export default { enUS, ptBR };
