import { toast } from "react-toastify";

import { takeLatest, call, put, all } from "redux-saga/effects";

import { API } from "~/constants/routes";
import { videoApi } from "~/services/api";

import { getVideoSuccess, getVideoFailure } from "./actions";

export function* getVideo({ payload }) {
  const { file } = payload;

  try {
    const response = yield call(videoApi.get, API.GET_VIDEO, {
      path: file,
    });

    if (response.data.errors.length)
      throw new Error(response.data.errors[0].message);

    yield put(getVideoSuccess(response.data));
  } catch (err) {
    toast.error(err.message);
    return yield put(getVideoFailure());
  }
}

export default all([takeLatest("@video/GET_VIDEO_REQUEST", getVideo)]);
