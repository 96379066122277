import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  notes: [],
};

export default function notes(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@notes/GET_NOTES_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@notes/GET_NOTES_SUCCESS": {
        draft.loading = false;
        draft.notes = action.payload.data;
        break;
      }
      case "@notes/GET_NOTES_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
