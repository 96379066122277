/* eslint-disable no-shadow */
import produce from "immer";

const INITIAL_STATE = {
  loading: false,
  course: {},
  courses: [],
  finishedCourses: [],
  courseLevels: [],
  finishedCourseLevels: [],
  library: [],
  libraryCourses: [],
};

export default function course(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@course/GET_COURSE_REQUEST": {
        draft.loading = true;
        draft.course = {};
        break;
      }
      case "@course/GET_COURSE_SUCCESS": {
        const {
          course,
          courses,
          finishedCourses,
          courseLevels,
          finishedCourseLevels,
        } = action.payload.data;
        draft.loading = false;
        draft.course = course;
        draft.courses = courses;
        draft.finishedCourses = finishedCourses;
        draft.courseLevels = courseLevels;
        draft.finishedCourseLevels = finishedCourseLevels;
        break;
      }
      case "@course/GET_COURSE_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@course/GET_LIBRARY_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@course/GET_LIBRARY_SUCCESS": {
        const { library, libraryCourses } = action.payload.data;
        draft.loading = false;
        draft.library = library;
        draft.libraryCourses = libraryCourses;
        break;
      }
      case "@course/GET_LIBRARY_FAILURE": {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
