export function getVideoRequest(data) {
  return {
    type: "@video/GET_VIDEO_REQUEST",
    payload: { data },
  };
}

export function getVideoSuccess(data) {
  return {
    type: "@video/GET_VIDEO_SUCCESS",
    payload: { data },
  };
}

export function getVideoFailure() {
  return {
    type: "@video/GET_VIDEO_FAILURE",
  };
}
