import styled from "styled-components";

import {
  IconCourses,
  IconAnswers,
  IconProgress,
  IconComments,
  IconWip,
} from "~/images";

/**
 * @todo cores definidas em hardcode
 */
export const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 115px;
  width: 100%;
  padding: 15px;
  align-items: center;
  margin-bottom: 20px;
  .type {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 50%;
    height: 40px;
    margin-right: 15px;
    &.finishedCourses {
      background-image: url(${IconCourses});
    }
    &.coursesInProgress {
      background-image: url(${IconAnswers});
    }
    &.hoursStudied {
      background-image: url(${IconProgress});
    }
    &.overallAverage {
      background-image: url(${IconComments});
    }
  }
  .typeWip {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 50%;
    height: 30px;
    margin-right: 15px;
    &.finishedCourses {
      background-image: url(${IconWip});
    }
    &.coursesInProgress {
      background-image: url(${IconWip});
    }
    &.hoursStudied {
      background-image: url(${IconWip});
    }
    &.overallAverage {
      background-image: url(${IconWip});
    }
  }
  .label {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
  }
  .quantity {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.text[theme.palette.type]};
  }
`;
