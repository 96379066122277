const enUS = {
  title: "My notes",
  createNote: "New note",
};

const ptBR = {
  title: "Minhas anotações",
  createNote: "Criar anotação",
};

export default { enUS, ptBR };
