import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    "layoutHeader"
    "layoutContent"
    "layoutFooter";
  grid-template-rows: 90px 1fr 70px;

  .account-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
  }

  .layout-content {
    grid-area: layoutContent;
  }

  .main-grid {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(min-content, 280px) auto;
    grid-template-areas: "sidebar main_content";
    grid-column-gap: calc(3rem + 15px);

    .sidebar-grid-area {
      grid-area: sidebar;
    }

    .content-grid-area {
      grid-area: main_content;
    }
  }
`;
